<template>
    <div class="Rectangle-Copy container-padding">            
        <p><span class="bold-text">Pogoj:</span>  Ali želite pridobiti oddajo pozitivnega mnenja z oceno 4-5 (zvezde)</p>
        <div class="checkbox-yes-no-option">
            <checkbox-group 
                :options="cond"
                :value="v"
                type="radio"
                width="20px" />
        </div>
        <div>
           <input type="text" class="roundedStyle textAreaStyle condTitle" placeholder="Kliknite in vpišite besedilo vprašanja" />
        </div>
        <div v-if="v">
            <testimonial-upload></testimonial-upload>
            <testimonial-upload></testimonial-upload>
            <testimonial-upload></testimonial-upload>
        </div>
        <div class="buttonsYesNo">
            <button class="buttonYes">DA</button>
            <button class="buttonNo">NE</button>
        </div>
        <div class="podatkiMnenja">
            <input style="display: block" type="text" class="roundedStyle textAreaStyle condTitle" placeholder="Vpišite naslov npr. PODAJTE SVOJE MNENJE" />
            <input style="display: block" type="text" class="roundedStyle podatek" placeholder="npr. Ime in priimek" />
            <input style="display: block" type="text" class="roundedStyle podatek" placeholder="npr. podjetje" />
            <input style="display: block" type="text" class="roundedStyle podatek" placeholder="npr. funkcija v podjetju" />
        </div>
    </div>
      
</template>

<script>
export default {
    name: "RatingCondition",
    data() {
        return {
            v: true,
            cond : [
                {    
                    label: "DA",
                    value: true,
                    checked: true,
                },{
                    label: "NE",
                    value: false,
                }
            ],
        }
    },
   
}
</script>

<style scoped>

.adminAnswerMargin {
    margin: 50px 0;
}

.condTitle{
    margin-top: 30px;
    background: #d8d8d8;
    border: none;
    width: 40%;
}

.condTitle::placeholder{
    color: #505050;
    font-size: 18px;
}


.Rectangle-Copy {
    width: 98%;
  margin: 30px 120px 48.2px 10.2px;
  padding: 10px 3.1px 68px 6.1px;
  border-radius: 12px;
  background-color: #f6f6f6;
}

.bold-text{
    font-weight: 600;
}

.container-padding{
    padding: 40px;
}

.checkbox-yes-no-option {
    padding: 5px 0 0 15px;
    display: flex;
    flex-flow: inherit;
}

.checkbox-yes-no-option > div {
    width: fit-content;
}

.buttonsYesNo button{
    width: 300px;
    height: 41.6px;
    margin: 49.9px 43.4px 40.6px 30.9px;
    opacity: 0.6;
    border-radius: 59px;
    border: solid 1px #707070;
    font-size: 18px;
    font-weight: normal;
}

.buttonsYesNo .buttonYes{
    margin-left: 0;
    margin-right: 15px;
}

.buttonsYesNo .buttonNo{
    margin-right: 0;
    margin-left: 15px;
}

.podatkiMnenja input{
    display: block;
    margin-bottom: 20px;
}

.podatek::placeholder{
    padding-left: 15px;
    font-size: 18px;
}

.podatek{
    width: 40%;
    border: 1px solid #707070;
}

</style>