<template>
    <div class="headerModalContent" id="headerModalContent">
        <button class="headerModalRow" @click="$emit('changepass')">Sprememba gesla</button>
        <button class="headerModalRow" @click="$emit('logout')">Odjavi se</button>
    </div>
</template>

<script>
export default {
    name: "HeaderUser",
    methods: {
        onClickOutside(e) {
            if (!document.getElementById("headerModalContent").contains(e.target)) {
                this.$emit("close");
            }
        },
    },
    mounted() {
        setTimeout(() => {
            window.addEventListener("click", this.onClickOutside);
        }, 10);
    },
    destroyed() {
        window.removeEventListener("click", this.onClickOutside);
    }
}
</script>

<style scoped>

</style>