<template>
    <div class="pageContent">
        <review-carousel :slides="slides" />
        <div class="reviewButtonsContainer">
            <button class="roundedStyle noColorBtn" @click="next(true)">{{ __('buttons.yes', this.$store.state.locale) }}</button>
            <button class="roundedStyle noColorBtn" @click="next(false)">{{ __('buttons.no', this.$store.state.locale) }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "RatingYesNo",
    props: { summary: Object },
    data() {
        return {
            slides: [
                {src: this.prependUrl(this.summary.image_1)},
                {src: this.prependUrl(this.summary.image_2)},
                {src: this.prependUrl(this.summary.image_3)},
            ],
        };
    },
    methods: {
        next(yes) {
            this.$emit("next", yes);
        },
    },
};
</script>

<style scoped>

.reviewButtonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.reviewButtonsContainer button {
    width: 45%;
}

</style>
