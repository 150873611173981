<template>
  <svg data-v-ef4bc576="" data-v-fde73a0c="" height="51" width="51" viewBox="0 0 50 50" class="vue-star-rating-star" step="100"><linearGradient data-v-ef4bc576="" id="tyz97l" x1="0" x2="100%" y1="0" y2="0"><stop data-v-ef4bc576="" offset="100%" stop-color="var(--main-color)" stop-opacity="1"></stop><stop data-v-ef4bc576="" offset="100%" stop-color="#fff" stop-opacity="1"></stop></linearGradient><filter data-v-ef4bc576="" id="p8jfke" height="130%" width="130%" filterUnits="userSpaceOnUse"><feGaussianBlur data-v-ef4bc576="" stdDeviation="0" result="coloredBlur"></feGaussianBlur><feMerge data-v-ef4bc576=""><feMergeNode data-v-ef4bc576="" in="coloredBlur"></feMergeNode><feMergeNode data-v-ef4bc576="" in="SourceGraphic"></feMergeNode></feMerge></filter><!----><polygon data-v-ef4bc576="" points="24.227272727272727,2.525252525252525,9.075757575757574,50,46.95454545454545,19.696969696969695,1.5,19.696969696969695,39.378787878787875,50" fill="url(#tyz97l)" stroke="var(--main-color)" stroke-width="1" stroke-linejoin="miter"></polygon><polygon data-v-ef4bc576="" points="24.227272727272727,2.525252525252525,9.075757575757574,50,46.95454545454545,19.696969696969695,1.5,19.696969696969695,39.378787878787875,50" fill="url(#tyz97l)"></polygon></svg>
</template>

<script>
export default {
    name: "SingleStar"

}
</script>

<style>

</style>