<template>
    <div class="vprasanje-container en-vec-odgovorov">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>
        <table class="st no-page-break" v-if="dataAnalysis">
            <tr>
                <th>{{ __("analysis.answer", locale) }}</th>
                <th>{{ __("analysis.frequency", locale) }}</th>
                <th>{{ __("analysis.percentage", locale) }}</th>
            </tr>
            <tr v-for="(answer,i) of dataAnalysis" :key="i">
                <td>{{ answer.value }}</td>
                <td>{{ answer.frequency }}</td>
                <td>{{ roundMix(((answer.frequency / frequencyTotal) * 100)) }}%</td>
            </tr>
            <tr class="final">
                <td>{{ __("analysis.sumarium", locale) }}</td>
                <td>{{ frequencyTotal }}</td>
                <td>100%</td>
            </tr>
        </table>
        <div class="grafi">
            <div class="graf">
                <canvas :id="'graph-check-anwser'+this.question.id" style="width:100%;max-width:800px"></canvas>
            </div>
        </div>
    </div>
</template>


<script>

import { Chart, registerables } from 'chart.js';

export default {
    name: "AnlCheckAnswer",
    props: {locale: String, question: Object},
    data() {
        return {
            dataAnalysis: null,
            frequencyTotal: 0,
        }
    },
    methods: {
        load() {
            let url = "/analysis/query-answer/" + this.question.id;
            api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
                this.drawGraph(document.getElementById('graph-check-anwser'+this.question.id), this.dataAnalysis);
            }).finally(() => {
                this.$emit("loaded");
            });
        },
        drawGraph(ctx, data, format = "pie") {
            let tmp = JSON.parse(JSON.stringify(data));
            let labels = tmp.map((o) => o["value"] );
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: [...this.colorsPalette(labels.length)],
                        data: tmp.map((o) => o["frequency"]),
                    }]
                }
            };
            new Chart(ctx, graphData);
        },

    },
    created() {
        this.load();
    },
    watch: {
        dataAnalysis: {
            deep: true,
            handler(value) {
                if (value) {
                    this.frequencyTotal = Object.values(JSON.parse(JSON.stringify(value))).reduce((partial_sum, a) => partial_sum + a.frequency, 0);
                }
            },
        }
    },
}

</script>

<style scoped>

.vprasanje-container {
    page-break-inside: avoid;
}

.final td {
    border-top: 1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

.st tr td {
    width: 33.33%;
}

.st th {
    font-size: 14px !important;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;
}

tr td, tr th {
    text-align: center;
}

th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right !important;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left !important;
}

</style>