<template>
    <div class="surveyContainer">
        <div v-for="(question, index) in savedSurvey.questions" :key="index" class="draggableItemContainer">
            <button class="draggableItemHeader roundedStyle handle" @click="open = !open">{{ question.name }}</button>
            <transition name="fade">
                <div v-if="open">
                    test
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "Analysis",
    props: { savedSurvey: Object },
    data() {
        return {
            open: false,
            sidebar: [
                {label: "Analiza", active: true, disabled: true},
                {label: "Nazaj", name: "back", active: false },
            ]
        }
    },
    methods: {
        load() {
            let url = "/survey/show/" + this.id;
            api.post(url).then(response => {
                console.log(response.data)
            })
        }
    },
    created() {
        this.load();
        this.$emit("sidebar", this.sidebar);
    }
}
</script>

<style scoped>

.draggableItemHeader {
    background-color: var(--light-grey);
    border: solid 1px var(--light-grey);
    color: var(--dark-grey);
    text-align: left;
    padding: 12px 30px;
    font-size: 18px;
    width: 100%;
    text-transform: none;
}

.draggableItemContainer {
    margin-bottom: 2rem;
}

</style>