<template>
    <div class="vprasanje-container mnenje-odgovor">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>
        <div class="no-page-break">
            <table class="st4" v-if="dataAnalysis">
                <tr>
                    <th>{{ __("analysis.scale", locale) }}</th>
                    <th>{{ __("analysis.answer", locale) }}</th>
                    <th>{{ __("analysis.percentage", locale) }}</th>
                </tr>
                <tr v-for="(g,i) in dataAnalysis.stats">
                    <td>{{ i }}</td>
                    <td>{{ g }}</td>
                    <td>{{ roundMix(((g / mySum(dataAnalysis.stats)) * 100)) }}%</td>
                </tr>
                <tr class="final">
                    <td>{{ __("analysis.sumarium", locale) }}</td>
                    <td>{{ mySum(dataAnalysis.stats) }}</td>
                    <td>100%</td>
                </tr>
                <tr class="average final">
                    <td>{{ __("analysis.average", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>{{ roundMix(average(dataAnalysis.stats)) }}</td>
                </tr>
            </table>
            <div class="grafi">
                <div class="graf">
                    <canvas :id="'graph-scale-rating'" style="width:100%;max-width:800px"></canvas>
                </div>
            </div>
        </div>

        <span class="vprasanje komentar-naslov">{{ __("analysis.comment", locale) }}</span>
        <div v-if="dataAnalysis">
            <div v-for="(a,i) in dataAnalysis.feedbacks" :key="i+'feedback'">
                <div class="ocena-container">
                    <img v-for="j in parseInt(a.value)" :key="j+'ocena'" class="ocena-zvezdica" alt="Zvezdica"
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA2CAMAAABKkMFiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA2UExURUdwTMYJO8gLPMcKPcYKPMcKPsYJO8YJPNcfSMYJPMwMQMYJO8YJO8cKO8cNP8YKPMUJPMUJO0aEhGcAAAARdFJOUwCKLld8Oe2nCLcU3MiZJmFuIE/TkgAAATNJREFUSMfFluuSgyAMhbknQdHy/i+7tlutUgyQmZ09P3U+DjlgolKMnBLKZy8kKZPUMgtNaSNJaikzpRdJUkuJKb3JYVOTd5lBEg4SpJajpnAiQWo5ZgoXcsB0yVctQssu0+itgznkUmEGZ32sIWjqSGUBg0dRLjWRcoHknqUj5XHRy1iA0nvHwygdpSIMgYCnaEEIVg6fAcsTTZ1g+r4MSQoqpTtAXb+1Wgq2Uc2MPRZkh6KTgl/doL8zWIa06m92CyPX7qKJIScORPZUsLu3lzLCaPlwtfTyqaIhFXlRb7SzV37uDPcc7fRblZ26wvUfbj0erh/2/j9l3dv/47w6PvbhsTaiDa7cFbrQCPcZbdC1YRd1YMPd3qZ4N1q33hhu524G7utdIN8u2/oJMVH9r34ASnpO00Y4CiUAAAAASUVORK5CYII=">
                </div>
                <span class="komentar">{{ a.summary }}</span>
            </div>
        </div>

        <l-testimonials v-if="dataAnalysis" :testimonials="dataAnalysis.testimonials"
                        :headers="dataAnalysis.testimonial_headers"></l-testimonials>


    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
import reviewGraph from '../../../charts/review.js'

export default {
    name: "AnlReview",
    props: {locale: String, question: Object},
    data() {
        return {
            reviewGraph: reviewGraph,
            dataAnalysis: null,
        }
    },
    methods: {
        load() {
            let url = "/analysis/query-answer/" + this.question.id;
            api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
                this.$nextTick(function () {
                    this.drawGraph(document.getElementById('graph-scale-rating'), this.dataAnalysis.stats);
                })
            }).finally(() => {
                this.$emit("loaded");
            });
        },
        drawGraph(ctx, data, format = "bar") {
            let labels = Object.keys(data);
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: this.colors1to5(),
                        data: Object.values(data)
                    }]
                },
                options: {
                    plugins:{
                        legend: {display: false},
                        title: {
                            display: true,
                            text: "Feedback",
                        },
                        datalabels: {
                            display: true,
                            align: 'center',
                            anchor: 'center',
                            font: {
                                size: 14
                            },
                            color: "#ffffff",
                            formatter: function (value, index, values) {
                                if (value === 0)
                                    return "";
                                if (value !== 0) {
                                    return value;
                                }
                            }
                        }

                    },
                }
            };
            new Chart(ctx, graphData);
        },
        mySum(graph) {
            let sum = Object.values(graph).reduce((partial_sum, a) => partial_sum + a, 0);
            return sum === 0 ? 1 : sum;
        },
    },
    created() {
        this.load();
    },
}

</script>

<style scoped>

.vprasanje-container {
    page-break-inside: avoid;
}

.final td {
    border-top: 1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.final.average td {
    border-top: none;
    padding-top: 0;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

.iz tr td:nth-child(1) {
    width: 50%;
    padding-left: 15px;
}

tr td, tr th {
    text-align: center;
}

th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}


table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right !important;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left !important;
}

.komentar-naslov {
    margin-top: 60px;
}

.komentar {
    font-size: 14px;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e6e6e6;
}

.ocena-container {
    margin-top: 30px;
}

.ocena-zvezdica {
    width: 30px;
    height: auto;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;;
}

</style>