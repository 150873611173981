<template>
    <div class="surveyListTableRow">
        <confirm-dialogue ref="confirmDialogue" okBtn="Izbriši" cancelBtn="Prekliči" :message="'Ali ste prepričani, da želite izbrisati anketo ' + survey.intern_name"></confirm-dialogue>

        <div class="table tableColName flexElement" style="width:6%;">{{ survey.id }}</div>
        <div class="tableCol tableColName flexElement">
            {{ survey.intern_name }}
        </div>
        <div class="tableCol tableColDate">{{ survey.updated_at }}</div>
        <div class="tableCol tableColAuthor">{{ survey.author }}</div>
        <div class="tableCol tableColAuthor">{{ survey.finished }}/{{ survey.invited }}</div>
        <div class="tableCol tableColActions flexElement">
            <button class="iconBtn" @click="editSurvey" title="Uredi"><img src="../../assets/img/edit_icon.svg" alt="Edit"></button>
            <button class="iconBtn" @click="deleteSurvey" title="Izbriši"><img src="../../assets/img/trash_icon.svg" alt="Delete"></button>
            <button v-if="!duplicating" class="iconBtn" @click="duplicateSurvey" title="Podvoji"><img src="../../assets/img/duplicate_icon.svg" alt="Duplicate"></button>
            <div class="loader" v-else></div>
            <button v-if="survey.status != 'draft'" class="iconBtn" @click="analyzeSurvey" title="Analiza"><img src="../../assets/img/analyze_icon.svg" alt="Analyze"></button>

        </div>
        
        <button v-if="!list" class="dragSurveyBtn flexElement handle">
            <img src="../../assets/img/drag_icon.svg" alt="-">
        </button>
    </div>
</template>

<script>
export default {
    name: "DraggableSurveyRow",
    props: {survey: Object, list: Boolean},
    data() {
        return {
            duplicating: false
        }
    },
    methods: {
        editSurvey() {
            this.$router.push({name: "EditSurvey", params: {id: this.survey.id}});
        },
        duplicateSurvey() {
            this.duplicating = true;
            let url = "/survey/duplicate/" + this.survey.id;
            api.post(url).then(response => {
                this.$router.push({name: "EditSurvey", params: {id: response.data.data.id}});
                this.duplicating = false;
            }).catch(error => {
                this.duplicating = false;
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            })
        },
        async deleteSurvey() {
            const confirm = await this.$refs.confirmDialogue.open();
            if (confirm) {
                let url = "/survey/destroy/" + this.survey.id;
                api.post(url).then(response => {
                    this.$emit("reload");
                    this.$root.$children[0].$refs.warningRef.show("Anketa je bila izbrisana.");
                }).catch(error => {
                    let errors = Object.keys(this.errorCatching(error).errors);
                    this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
                })
            }
        },
        analyzeSurvey() {
            this.$router.push({name: "Analysis", params: {id: this.survey.id}});
        },
    },
    computed: {
        formatDate() {
            let date = new Date(this.survey.updated_at);
            let result = date.getDate() + "." + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + (date.getDate() > 9 ? '' : '0') + "." + date.getFullYear();
            return result
        }
    }
}
</script>

<style scoped>

.dragSurveyBtn {
    position: absolute;
    right: 15px;
}

.publishClass {
    background-color: var(--main-color);
    color: #fff;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    position: absolute;
    margin-left: 7px;
}

</style>