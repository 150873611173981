<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>

        <div class="adminAnswersInput">
            <div class="adminAnswersInputSingle">
                <span>Opis najmanše ocene</span>
                <input type="text" class="roundedStyle textAreaStyle" placeholder="npr. Ne strinjam se" v-model="item.scale_min">
            </div>
            <div class="adminAnswersInputSingle">
                <span>Opis največje ocene</span>
                <input type="text" class="roundedStyle textAreaStyle" placeholder="npr. Popolnoma se strinjam" v-model="item.scale_max">
            </div>
        </div>

        <checkbox-group class="marginTop30" :options="answersNo"  width="100%" :name="item.index+'no'" @change="handleChangeNo"/>
        
        <div class="adminAnswersInput adminAnswersInputSpecial" v-if="answersNo[0].checked">
            <div v-for="(a,i) in possibleAnswers" :key="i" class="adminAnswersInputSingle adminAnswersInputSingleSpecial flexElement">
                <input type="text" class="roundedStyle textAreaStyle" :placeholder="'Vpišite besedilo trditve '+(i+1)" v-model="a.placeholder" @blur="answerInput">
                <button v-if="possibleAnswers.length > 1" @click="deletePossibleAnswer(i)" class="iconBtn"><img src="../../../assets/img/trash_icon.svg" alt="Delete"></button>
            </div>
            <div class="adminAnswersInputSingle adminAnswersInputSingleSpecial">
                <div class="roundedStyle textAreaStyle disabledRoundedStyle">
                    <span>Vpišite besedilo trditve {{ possibleAnswers.length + 1}}</span>
                    <button class="addOptionBtn iconBtn" @click="addAnswer"><img src="../../../assets/img/add_icon_small.svg" alt="ADD"></button>
                </div>
            </div>
        </div>
        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" :oldValue="item.banner"  @change="handleImage" />
        </div>

        <checkbox-group :options="checkbox" width="100%" :name="item.type+item.index" @change="handleChange" />

        <div class="admConditionContainer">
            <checkbox-group :options="conditionalCheckbox"  width="100%" :name="item.type + item.index" @change="handleConditional" />
            <div class="admConditionalInput normalMargin">
                <div class="admLabel">Vprašanje</div>
                <div class="oneLineInput">
                    <div class="existingText no-wrap">Za trditev "(prikaz trditve z oceno 1-2)" ste podali slabo oceno.</div>
                    <input type="text" class="textAreaStyle roundedStyle" v-model="item.explain">
                </div>
            </div>
            <div class="admConditionalInput">
                <div class="admLabel">Splošno vprašanje</div>
                <input type="text" class="textAreaStyle roundedStyle" v-model="item.explain_question">
            </div>
            <div class="admConditionalInput">
                Splošno vprašanje se pojavi pri vsaj <input type="number" class="textAreaStyle roundedStyle numberInputStyle" v-model="item.limit"> trditvah s slabo oceno.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdmScaleRating",
    props: {item: Object},
    data() {
        return {
            possibleAnswers: this.item.possible_answers.length > 0 ? this.item.possible_answers : [
                {placeholder: ""},
                {placeholder: ""},
            ],
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            answersNo: [{boldLabel: "Več trditev", checked: (this.item.possible_answers.length > 1 || this.item.possible_answers[0].placeholder)}],
            conditionalCheckbox: [{boldLabel: "POGOJ:", label: "Ali želite pridobiti obrazložitev, v primeru ocenitve podane trditve z oceno 1-2?", checked: this.setBool(this.item.cond)}],
            checkbox: [{boldLabel: "Obvezna ocena:", label: "ocena bo avtomatsko označena.", checked: this.setBool(this.item.mandatory)}],
        }
    },
    methods: {
        addAnswer() {
            this.possibleAnswers.push({placeholder: ""});
        },
        handleChangeNo(value) {
            this.answersNo[0].checked = value.length > 0;
            if (!this.answersNo[0].checked) 
                this.possibleAnswers = [{placeholder: ""}];
            this.answerInput();
        },
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        answerInput() {
            this.item.possible_answers = this.possibleAnswers;
        },
        handleImage(value) {
            this.item.banner = value;
        },
        deletePossibleAnswer(index) {
            this.possibleAnswers.splice(index, 1);
            this.answerInput();
        },
        handleConditional(value) {
            this.item.cond = !!value.length;
        },
        handleChange(value) {
            this.item.mandatory = !!value.length;
        },
    },
    created() {
        if (this.item.possible_answers.length < 1)
            this.item.possible_answers.push({placeholder: ""});
    }
}
</script>

<style scoped>

.adminAnswersInputspecial {
    flex-direction: column;
}

.adminAnswersInputSpecial input, .disabledRoundedStyle {
    width: 800px;
}

.adminAnswersInputSingleSpecial {
    width: 100%;
}

.marginTop30 {
    margin-top: 30px;
}

</style>