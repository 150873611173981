<template>
    <div class="surveyContainer">

        <div class="inputRow">
            <div class="uppercaseTitle">Vpiši osnovne značilnosti ankete</div>
        </div>

        <div class="inputRow">
            <div class="inputName inputCol">Avtor ankete</div>
            <div class="inputCol">
                <div class="textAreaStyle roundedStyle">{{ survey.author }}</div>
            </div>
        </div>

        <create-survey-input v-for="(d, i) in surveyData" :key="'surveyData'+i" :data="d" :oldValue="survey[d.key]" @change="handleChange(...arguments, d.key)"/>

        <create-survey-input :key="'folderKey' + folders.length" :data="{type: 'select', name: 'Mapa', placeholder: 'Ni izbrane mape', key: 'folder_id', options: this.folders}" :oldValue="survey['folder_id']" @change="handleChange(...arguments, 'folder_id')"/>


        <div class="inputRow surveyInputBckgTitleMargin">
            <div class="surveyInputBckgTitle roundedStyle">Naslovna stran ankete</div>
        </div>

        <create-survey-input v-for="(d, i) in naslovnaData" :oldValue="survey[d.key]" :key="'naslovnaData'+i" :data="d"
                             @change="handleChange(...arguments, d.key)"/>

        <div class="inputRow surveyInputBckgTitleMargin">
            <div class="surveyInputBckgTitle roundedStyle">Zadnja stran ankete</div>
        </div>

        <create-survey-input v-for="(d, i) in zadnjaData1" :key="'d'+i" :data="d"
                             @change="handleChange(...arguments, d.key)" :oldValue="survey[d.key]"/>

        <div class="inputRow">Hiperpovezave do socialnih omrežij (Klikni na ikone, ki jih želite dodati)</div>

        <div class="inputRow noHeight">
            <social-checkbox-group class="socialIconCheckboxes" :options="socialOptions"
                                   :connections="survey.connections" name="social" @change="socialCheck"/>
        </div>

        <transition-group name="fade">
            <span v-for="(d, i) in socialInputs" :key="'e'+i">
                <create-survey-input v-if="isSocialChecked(d.id)" class="socialIconInputs" :data="d"
                                     @change="socialLinkInput(...arguments, d.id)"
                                     :oldValue="isSocialChecked(d.id).url"/>
            </span>
        </transition-group>

        <div class="divider"></div>

        <create-survey-input v-for="(d, i) in zadnjaData2" :key="'f'+i" :data="d"
                             @change="handleChange(...arguments, d.key)" :oldValue="survey[d.key]"/>

    </div>
</template>

<script>
export default {
    name: "SurveyStep1",
    props: {savedSurvey: Object, socialOptions: Array, folders: Array},
    data() {
        return {
            survey: this.savedSurvey,
            surveyData: [
                {
                    type: "text",
                    name: "Interno ime ankete",
                    placeholder: "Vpišite interno ime ankete",
                    key: "intern_name",
                    required: false
                },
                {
                    type: "text",
                    name: "Prikazano ime ankete (zgoraj)",
                    placeholder: "npr. LEADERSHIP ACADEMY",
                    key: "name",
                    required: false
                },
                // {type: "password", name: "Geslo", placeholder: "Vpišite varno geslo", key: "password"},
                {
                    type: "file",
                    name: "Prikazan logotip",
                    placeholder: "Izberite datoteko",
                    key: "logo",
                    id: "logo_upload"
                },
                {
                    type: "file",
                    name: "Ikona spletnega mesta",
                    placeholder: "Izberite datoteko",
                    key: "favicon",
                    id: "fav_upload"
                },
                {
                    type: "file",
                    name: "Naslovna slika",
                    placeholder: "Izberite datoteko",
                    key: "head_img",
                    id: "headimg_upload"
                },
                {
                    type: "checkbox",
                    name: "Jezik",
                    radio: true,
                    options: [{label: "SLO", value: "sl", checked: this.savedSurvey.locale == "sl"}, {
                        label: "ANG",
                        value: "en",
                        checked: this.savedSurvey.locale == "en"
                    }],
                    key: "locale"
                },
                {
                    type: "checkbox",
                    name: "Tip ankete",
                    radio: true,
                    options: [
                        {
                            label: "CPOEF",
                            value: "default",
                            checked: this.savedSurvey.special_type == "default"
                        },
                        {
                            label: "MBA",
                            value: "MBA",
                            checked: this.savedSurvey.special_type == "MBA"
                        }
                    ],
                    key: "special_type"
                },
            ],
            naslovnaData: [
                {
                    type: "text",
                    name: "Naslovno besedilo",
                    placeholder: "npr. Anketa o zadovoljstvu",
                    key: "heading_txt"
                },
                {
                    type: "textarea",
                    name: "Uvodno besedilo",
                    placeholder: "npr.  Želimo pridobiti povratno informacijo o izvedenem izobraževanju, , zato vas prosimo, da izpolnite kratko anketo.",
                    key: "intro_txt"
                },
                {type: "text", name: "Besedilo v gumbu", placeholder: "npr. Začni", key: "button_txt"},
            ],
            zadnjaData1: [
                {
                    type: "textarea",
                    name: "Naslovno besedilo",
                    placeholder: "Odgovorili ste na vsa vprašanja!",
                    key: "finish_txt"
                },
                {type: "text", name: "Zaključno besedilo", placeholder: "Hvala za sodelovanje", key: "thank_you_txt"},
                {
                    type: "file",
                    name: "Slika na zadnji strani",
                    placeholder: "Izberite datoteko",
                    key: "foot_img",
                    id: "footimg_upload"
                },
            ],
            zadnjaData2: [
                {type: "text", name: "Naziv", placeholder: "Naziv izvajalca ankete", key: "title"},
                {type: "text", name: "Naslov", placeholder: "Naslov izvajalca ankete", key: "address"},
            ],
        }
    },
    methods: {
        handleChange(value, key) {
            this.survey[key] = value;
            if (key == "type")
                this.survey.type = value
        },
        socialCheck(value) {
            this.survey.connections = value.map(a => {
                let isIn = this.isSocialChecked(a);
                let url;
                if (isIn)
                    url = isIn.url;
                return {link_id: a, url: url};
            });
        },
        socialLinkInput(value, id) {
            this.survey.connections.find(a => a.link_id == id).url = value;
        },
        isSocialChecked(id) {
            return this.survey.connections.find(a => a.link_id == id);
        }
    },
    computed: {
        socialInputs() {
            let tmp = this.socialOptions.map(a => {
                return {id: a.id, type: "text", name: a.name, placeholder: "Vpišite ustrezno povezavo"}
            });
            return tmp;
        },
    },
}
</script>

<style scoped>

.inputColContent {
    margin-top: 40px
}

.socialIconCheckboxes {
    padding-bottom: 40px;
}

</style>