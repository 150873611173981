<template>
    <div id="app" class="pageContainer" ref="pageContainer">
        <router-view />
        <warning-popup ref="warningRef" />
    </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },

}
</script>
<style>

</style>
