//VUE
import axios from "axios";

export const authAPI = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest'

        }
    },
});

