export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null
    },

    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user
        },
    },

    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value
        },

        SET_USER(state, value) {
            state.user = value
        }
    },

    actions: {
        async signIn({dispatch}, credentials) {
            await api.get('/sanctum/csrf-cookie');
            await api.post('/login', credentials);
            return dispatch('me')
        },

        async signOut({dispatch}) {
            await api.post('/logout')
            return dispatch('me')
        },

        me({commit}) {
            return api.post('/user').then((response) => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_USER', response.data.data)
            }).catch(() => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
            });
        }
    }
}