<template>
    <div v-if="survey" class="pageContainer">
        <custom-header :admin="false" :name="survey.name" :logo="setLogo"/>
        <transition name="router" mode="out-in">
            <router-view :survey="survey" ref="pageHandler"></router-view>
        </transition>
    </div>
    <page-template v-else-if="invalidURL" :noButtons="true">
        <div class="pageNotFound">
            Ups, ta stran ne obstaja
        </div>
    </page-template>
    <div v-else>
        <div class="loaderContainerAbsolute">
            <div class="loader"></div>
        </div>
        <div class="loaderOverlay"></div>
    </div>
</template>

<script>
export default {
    name: "TheSurvey",
    props: {id: String},
    data() {
        return {
            invalidURL: false,
            survey: null,
            tokenName: "progressToken" + this.$route.params.token,
            question: null,
        }
    },
    methods: {
        async load() {
            try {
                let token = localStorage.getItem(this.tokenName);

                // Če na napravi ni tokna, ga naloži
                if (!token) {
                    const getTokenResponse = await api.post("/answer/get-free-token/" + this.$route.params.token);
                    localStorage.setItem(this.tokenName, getTokenResponse.data.data);
                    token = getTokenResponse.data.data;
                }

                // Naloži anketo
                const getSurveyRespnse = await api.post("/survey/by-token/" + this.$route.params.token)
                this.survey = getSurveyRespnse.data.data.survey;
                this.$store.commit('changeLocale', this.survey.locale);
                const favicon = document.getElementById("favicon");
                favicon.href = this.survey.favicon ? this.prependUrl(this.survey.favicon) : "https://www.cpoef.si/wp-content/themes/cpoef/img/icn/favicon.ico";

                // Naloži, na katerem koraku je anketiranec
                const getStepResponse = await api.post("/answer/get-step/" + token);

                // Anketa je bila rešena do konca
                if (getStepResponse.data.data === "finish") {
                    this.question = "finish";

                    // Prvič odprta ali brez rešenih vprašanj
                } else if (parseInt(getStepResponse.data.data) < 2) {
                    this.question = "start";

                    // Delno rešena
                } else {
                    this.$refs.pageHandler.setQuestion("start");
                    const confirm1 = await this.$refs.pageHandler.$refs.confirmDialogue.open();
                    if (confirm1) {
                        this.question = parseInt(getStepResponse.data.data);
                    } else {
                        let url = "/answer/reset-survey/" + token;
                        await api.post(url);
                        this.question = "start";
                    }
                }

                this.$refs.pageHandler.setQuestion(this.question);
            } catch (error) {
                console.log(error)
                this.invalidURL = true;
            }
        },
        loadPreview() {
            let url = "/survey/show/" + this.id;
            api.post(url).then(response => {
                this.survey = response.data.data;
                this.survey.preview = true;
                this.$store.commit('changeLocale', this.survey.locale);
            })
        }
    },
    created() {
        if (this.id) {
            this.loadPreview();
        } else {
            this.load();
        }
    },
    computed: {
        setLogo() {
            return this.survey.logo ? this.prependUrl(this.survey.logo) : require("../../assets/img/logo.png");
        }
    }
}
</script>

<style scoped>

</style>