<template>
    <transition name="router">
        <router-view ref="surveys" :key="'surveysKey' + surveysKey"></router-view>
    </transition>
</template>

<script>
export default {
    name: "Surveys",
    data() {
        return {
            sidebar: [
                {label: "Ankete", disabled: true, active: true},
                {label: "Vse ankete", name: "tabs", tab: {name: "SurveyListAll"}, active: false, disabled: false},
                // {label: "Vse ankete", name: "tabs", tab: {name: "SurveyList", params: {filter: "all-surveys"}}, active: false, disabled: false},
                {label: "Moje ankete", name: "tabs", tab: {name: "SurveyList", params: {filter: "my-surveys"}}, active: false},
                {label: "Ankete v teku", name: "tabs", tab: {name: "SurveyList", params: {filter: "active-surveys"}}, active: false},
                {label: "Ustvari novo", name: "tabs", tab: {name: "CreateSurvey"}, active: false},
                {label: "Nazaj", name: "AdminEntry", active: false},
            ],
            surveysKey: 1
        }
    },
    methods: {
        changeTab(tab) {
            this.setSidebar(false);
            this.$router.replace(tab);
            this.surveysKey++;
            this.setSidebar(true);
        },
        setSidebar(value) {
            let active;
            if (this.$route.name == "SurveyList") {
                active = this.sidebar.find(a => a.tab && a.tab.name == "SurveyList" && a.tab.params.filter == this.$route.params.filter);
            } else {
                active = this.sidebar.find(a => a.tab && a.tab.name == this.$route.name);
            }
            active.active = value;
            active.disabled = value;
        }
    },
    created() {
        if(this.$route.name == "Surveys")
            this.$router.push({name: "SurveyListAll"});
        this.setSidebar(true);
        this.$emit("sidebar", this.sidebar)
    }
}
</script>

<style>

</style>