<template>
    <div class="vprasanje-container lestvica1-5" v-if="dataAnalysis.length > 0">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>

        <div class="no-page-break" v-for="(a, i) in dataAnalysis" :key="i+'pi'">
            <table class="st4">
                <tr>
                    <th>{{ a.placeholder }}</th>
                    <th>{{ __("analysis.scale", locale) }}</th>
                    <th>{{ __("analysis.answer", locale) }}</th>
                    <th>{{ __("analysis.percentage", locale) }}</th>
                </tr>

                <tr>
                    <td rowspan="6"><img class="imageStyle" :src="prependUrl(a.image)" alt=""></td>
                </tr>
                <tr v-for="(g,i) in a.graph" :key="'graph'+i">
                    <td style="text-align: center;">{{ i }}</td>
                    <td>{{ g }}</td>
                    <td>{{ roundMix(((g / mySum(a.graph)) * 100)) }}%</td>
                </tr>

                <tr class="final">
                    <td>{{ __("analysis.sumarium", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>{{ mySum(a.graph) }}</td>
                    <td>100%</td>
                </tr>
                <tr class="average final">
                    <td>{{ __("analysis.average", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{{ roundMix(average(a.graph)) }}</td>
                </tr>
            </table>
            <span v-if="a.explanations" class="vprasanje komentar-naslov">{{ __("analysis.comment", locale) }}</span>
            <div v-if="a.explanations">
                <div v-for="(c,i) in a.explanations" :key="i+'feedback'">
                    <span class="komentar">{{ c.value }}</span>
                </div>
            </div>
            <div class="grafi">
                <div class="graf">
                    <canvas :id="'graph-scale-rating'+a.id+'pa'" style="width:100%;max-width:800px"></canvas>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import {Chart, registerables} from 'chart.js';
import scaleRatingGraph from '../../../charts/scaleRating.js'

export default {
    name: "AnlInstructorRating",
    props: {locale: String, question: Object},
    data() {
        return {
            dataAnalysis: [],
        }
    },
    methods: {
        async load() {
            let url = "/analysis/query-answer/" + this.question.id;
            await api.post(url).then(response => {
                if (Object.keys(this.$route.query).length === 0) {
                    this.dataAnalysis = response.data.data;
                } else {
                    this.dataAnalysis = response.data.data.filter(a => Object.values(this.$route.query).find(b => a.id == b));
                }
                this.$nextTick(function () {
                    this.drawGraphs();
                })
            }).finally(() => {
                this.$emit("loaded");
            });
        },
        drawGraphs() {
            let tmp = JSON.parse(JSON.stringify(this.dataAnalysis));
            for (let i = 0; i < tmp.length; i += 1) {
                this.drawGraph(document.getElementById('graph-scale-rating' + tmp[i]["id"] + 'pa'), tmp[i]);
            }
        },
        drawGraph(ctx, data, format = "bar") {
            let labels = Object.keys(data["graph"]);
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: this.colors1to5(),
                        data: Object.values(data["graph"])
                    }]
                },
                options: {
                    plugins:{
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: data["placeholder"],
                        },
                        datalabels: {
                            display: true,
                            align: 'center',
                            anchor: 'center',
                            font: {
                                size: 14
                            },
                            color: "#ffffff",
                            formatter: function (value, index, values) {
                                if (value === 0)
                                    return "";
                                if (value !== 0) {
                                    return value;
                                }
                            }
                        }
                    },

                }
            };
            new Chart(ctx, graphData);
        },
        mySum
            (graph) {
            let sum = Object.values(graph).reduce((partial_sum, a) => partial_sum + a, 0);
            return sum === 0 ? 1 : sum;
        }
        ,
    },
    created() {
        this.load();
    },
}

</script>

<style scoped>
.komentar-naslov {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 6px;
}

.komentar {
    font-size: 14px;
    display: block;
    padding: 5px 10px;
    margin-bottom: 3px;
    border-radius: 4px;
    width: 100%;
    background-color: #e6e6e6;
}

.vprasanje-container {
    page-break-inside: avoid;
}

.imageStyle {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    object-fit: cover;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.final td {
    border-top: 1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.final.average td {
    border-top: none;
    padding-top: 0;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

tr td, tr th {
    text-align: center;
}

th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;;
}

.st4 tr td {
    width: 25%;
}

</style>
