<template>
   <transition name="fade">
        <div class="confirmDialogueModal" v-if="isVisible" @click="close">
            <div class="confirmDialougeWindow" @click.stop>
                <div class="confirmDialogueMessage">
                    <div class="modalText">Označite enega ali več predavateljev, katerih rezulate želite izvoziti.<br />Za izvoz posameznega predavatelja kliknite na njegovo ime.</div>
                    <div class="checkboxGroupContainer flexDirectionColumn">
                        <div v-for="(ins,i) in instructors" :key="'insCheck' + i" class="instructorCheckRow">
                            <label class="singleCheckboxContainer">
                                <input name="instructorsCheck" type="checkbox" :value="ins.id" @change="$emit('change', value)" v-model="picked">
                                <span class="customCheckbox"></span>
                            </label>
                            <div class="instructorPlaceholder" title="Natisni" @click="printSingle(ins.id)">{{ ins.placeholder }}</div>
                        </div>
                    </div>
                </div>
                <div class="confirmDialogueBtns">
                    <button class="confirmDialogueButton roundedStyle mainColorBtn" @click="print">Natisni</button>
                    <button class="confirmDialogueButton roundedStyle greyBtn" @click="close">Zapri</button>
                </div>
            </div>
        </div>
   </transition>

</template>

<script>
export default {
    name: "CustomModal",
    props: {id: String|Number},
    data() {
        return {
            isVisible: false,

            instructors: [],
            picked: []
        }
    },
    methods: {
        load() {
            let url = "/survey/instructors-list/" + this.id;
            api.post(url).then(response => {
                this.instructors = response.data.data;
            })
        },
        print() {
            this.$emit("print", this.picked);
        },
        printSingle(value) {
            this.$emit("print", [value]);
        },
        open() {
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
        },
    },
    created() {
        this.load();
    },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.confirmDialogueModal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 1000;
}

.confirmDialougeWindow {
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    max-height: 93vh;
    overflow: auto;
}

.confirmDialogueMessage {
    padding: 1.5rem 0 2rem 0;
    padding-top: 0.5rem;
}

.confirmDialogueBtns {
    border-top: #d8d8d8 solid 1px;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirmDialogueButton {
    width: 160px;
    padding: 8px;
}

.modalText {
    margin-bottom: 1rem;
    font-weight: 600;
}

.flexDirectionColumn{
    flex-direction: column;
}

.instructorCheckRow {
    margin: 0.5rem 0;
}

.instructorPlaceholder {
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
    cursor: pointer;
}

.instructorPlaceholder:hover {
    color: var(--main-color);
    font-weight: 500;
}

</style>