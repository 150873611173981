<template>
    <page-template :noButtons="true">
        <div class="pageNotFound">
            Ups, ta stran ne obstaja
        </div>
    </page-template>
</template>

<script>
export default {}
</script>

<style scoped>

.pageNotFound {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>