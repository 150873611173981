<template>
  <div class="componentContainer">
    <div class="imageContainer"  :class="{'pageImageLarge': biggerImage}">
      <img :src="setSrc" alt="Page image" class="pageImage">
      <div class="imageOverlay"></div>
    </div>
    <div class="adminContainer" v-if="adminPanel">
        <admin-sidebar @openPage="handleOpenPage" :sidebar="sidebar" />
        <div class="adminContent">
            <slot></slot>
        </div>
    </div>
    <div class="widthContainer" v-else>
      <!-- <div class="contentContainer" :class="{noButtons: noButtons}">
        <div v-if="title" class="question title">{{ title }}</div>
        <div v-else class="question">{{ question }}</div>
        <slot></slot>
      </div> -->
      <slot></slot>
      <div class="btnsContainer" v-if="!noButtons">
        <button v-if="!noBack" class="whiteBtn roundedStyle" @click="$emit('back')">{{ __("buttons.previous",$store.state.locale) }}</button>
        <span v-else></span>
        <button class="mainColorBtn roundedStyle" @click="$emit('next')">{{ __("buttons.next", $store.state.locale) }}</button>
      </div>
      

        <div v-if="loading" class="loaderContainerAbsolute">
            <div class="loader"></div>
        </div>
        <div v-if="loading" class="loaderOverlay"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTemplate",
  props: { biggerImage: Boolean, title:String, noButtons: Boolean, noBack: Boolean, adminPanel: Boolean, sidebar: Array, banner: String, loading: Boolean },
  data() {
    return {
      src: require("../assets/img/page-small.jpg"),
      srcLarge: require("../assets/img/page-large.jpg")
    };
  },
  methods: {
      handleOpenPage(value) {
          this.$emit("openPage", value)
      }
  },
  computed: {
      setSrc() {
          if (this.banner) {
              return this.prependUrl(this.banner);
          } else {
              return this.biggerImage ? this.srcLarge : this.src
          }
      }
  }
}
</script>

<style scoped>

.loaderOverlay {
    position: fixed;
    top: unset;
    bottom: unset;
    height: 100%;
}

.loader {
    position: fixed;
}

.componentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
}

.adminContainer {
    display: flex;
    padding: 100px 0;
    width: 100%;
}

.adminContent {
    padding: 0 150px;
    flex-grow: 1;
}

.question {
  padding: 12px 0;
  font-weight: 600;
}

.question.title {
  text-align: center;
  text-transform: uppercase;
}

.btnsContainer {
  padding: 32px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 99;
  background-image: linear-gradient(rgba(255,255,255, 0.6), rgba(255,255,255));
  width: 100%;
}

.btnsContainer button {
  width: 150px;
  font-weight: 500;
}

.imageContainer {
  height: 30%;
  width: 100%;
  position: relative;
}

.pageImageLarge {
  height: 48%;
}

.imageOverlay {
  opacity: 0.25;
}

.pageImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-grey);
}


</style>