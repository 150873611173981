<template>
    <div class="checkboxGroupContainer">
        <label v-for="(o,i) in options" :key="i" class="singleCheckboxContainer iconCheckboxStyle">
            <img :src="prependUrl(o.icon)" :alt="o.name">
            <input :name="name" type="checkbox" :value="o.id"  @change="$emit('change', value)" v-model="value">
            <span class="customCheckbox"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "SocialCheckboxGroup",
    props: { options: Array, radio: Boolean, name: String, connections: Array },
    data() {
        return {
            value: this.connections.map(a => a.link_id),
        }
    },
}
</script>

<style scoped>

</style>