<template>
    <div class="pageContent">
        <div class="ratingContainer">
            <!-- Opisi zvezdic so skriti -->
            <div v-if="false" class="ratingDescription">{{ ratingDescription }}</div>

            <star-rating inactive-color="#fff" border-color="var(--dark-grey)" :border-width="1" active-color="var(--main-color)" active-border-color="var(--main-color)" :show-rating="false" @rating-selected="handleChange" v-model="newValue" />
        <div v-if="validation && !newValue" class="validationAlert">{{ __("validation.markOneStar", $store.state.locale) }}</div>
        </div>
        <textarea class="textAreaStyle roundedStyle" :placeholder="__('rating.feedback', this.$store.state.locale)" :rows="8" v-model="feedback" @input="handleChange"></textarea>
    </div>
</template>

<script>
export default {
    name: "Rating",
    props: { validation: Boolean, qid: String|Number, value: Object, ratings: Array },
    data() {
        return {
            newValue: 0,
            feedback: null
        }
    },
    methods: {
        handleChange() {
            if (this.newValue)
                this.$emit("change", {value: this.newValue, question_id: this.qid, summary: this.feedback});
        }
    },
    computed: {
        ratingDescription() {
            switch(this.newValue) {
                case 1:
                    return this.ratings.length == 5 ? this.ratings.find(a => parseInt(a.value) == 1).title : this.__("rating.nothing", this.$store.state.locale);
                case 2:
                    return this.ratings.length == 5 ? this.ratings.find(a => parseInt(a.value) == 2).title : this.__("rating.something", this.$store.state.locale);
                case 3:
                    return this.ratings.length == 5 ? this.ratings.find(a => parseInt(a.value) == 3).title : this.__("rating.little", this.$store.state.locale);
                case 4:
                    return this.ratings.length == 5 ? this.ratings.find(a => parseInt(a.value) == 4).title : this.__("rating.abit", this.$store.state.locale);
                case 5:
                    return this.ratings.length == 5 ? this.ratings.find(a => parseInt(a.value) == 5).title : this.__("rating.alot", this.$store.state.locale);
                default:
                    return this.__("rating.stars", this.$store.state.locale);
            }
        }
    },
    created() {
        if(this.value) {
            this.newValue = parseInt(this.value.value);
            this.feedback = this.value.summary;
        }
        // if(this.$route.name === "QuestionPagePreview") {
        //     this.newValue = "0"
        // }
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if(value) {
                    this.newValue = parseInt(value.value);
                    this.feedback = value.summary;
                }
            }
        }
    }
}
</script>

<style scoped>

.ratingContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ratingDescription {
    text-align: center;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.pageContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.validationAlert {
    display: flex;
    justify-content: center;
}

@media (min-width:801px) {
    .ratingDescription {
        font-size: 15px;
        text-transform: uppercase;
    }
}

</style>