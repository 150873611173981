<template>
    <div class="settingsUserRow">
        <div class="settingsUserCol">
            <span v-if="user && !edit">{{ user.name }}</span>
            <input v-else v-model="newUser.name" type="text" class="textAreaStyle roundedStyle" placeholder="Ime in priimek">
        </div>
        <div class="settingsUserCol">
            <span v-if="user && !edit">{{ user.email }}</span>
            <input v-else v-model="newUser.email" type="email" class="textAreaStyle roundedStyle" placeholder="Elektronski naslov">
        </div>
        <div class="settingsUserCol">
            <input v-if="addNew" v-model="newPass" type="password" class="textAreaStyle roundedStyle" placeholder="Geslo" >
            <button v-else class="linkStyleBtn" @click="resetPass">Spremeni geslo</button>
            <span v-if="user && edit" class="editUserBtn">
                <button @click="updateUser" class="iconBtn"><img src="../../assets/img/check_icon.svg" title="Shrani" alt="Save"></button>
                <button @click="cancelEdit" class="iconBtn"><img src="../../assets/img/cancel_icon.svg" title="Prekliči" alt="Cancel"></button>
            </span>
            <span v-else-if="user" class="editUserBtn">
                <button @click="edit = !edit" class="iconBtn"><img src="../../assets/img/edit_icon.svg" title="Uredi" alt="Edit"></button>
                <button @click="deleteUser" class="iconBtn"><img src="../../assets/img/trash_icon.svg" title="Izbriši" alt="Delete"></button>
            </span>
            <span v-else class="editUserBtn">
                <button @click="addUser" class="iconBtn"><img src="../../assets/img/add_icon_small.svg" alt="Add"></button>
            </span>
        </div>

        <confirm-dialogue v-if="user" ref="confirmDialogue" :okBtn="confirmData.okBtn" :cancelBtn="confirmData.cancelBtn" :message="confirmData.message"></confirm-dialogue>
    </div>
</template>

<script>
export default {
    name: "SettingsUser",
    props: { user: Object, addNew: Boolean },
    data() {
        return {
            // newUser: JSON.parse(JSON.stringify(this.user)) || {name: null, email: null},
            newUser: this.user || {name: null, email: null},
            newPass: null,
            edit: false,
            confirmData: {
                okBtn: "",
                cancelBtn: "Prekliči",
                message: "",
            }
        }
    },

    methods: {
        async deleteUser() {
            if (this.$store.state.auth.user.id == this.user.id) {
                this.$root.$children[0].$refs.warningRef.show("Lastnega uporabniškega računa ni mogoče izbrisati.");
                return false;
            }
            this.confirmData.okBtn = "Izbriši";
            this.confirmData.message = "Ali ste prepričani, da želite izbrisati uporabnika " + this.user.name;
            const confirm = await this.$refs.confirmDialogue.open();
            if (confirm) {
                this.$emit("loading", true);
                let url = "/user/destroy/"+this.user.id;
                api.post(url).then(response => {
                    this.$root.$children[0].$refs.warningRef.show("Uporabniški račun je bil izbrisan.");
                    this.$emit("reload");
                }).catch(error => {
                    let errors = Object.keys(this.errorCatching(error).errors);
                    this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
                    this.$emit("loading", false);
                })
            }
        },
        addUser() {
            this.$emit("loading", true);
            let url = "/user/store";
            let data = {
                name: this.newUser.name,
                email: this.newUser.email,
                password: this.newPass,
            }
            api.post(url, data).then(response => {
                this.$root.$children[0].$refs.warningRef.show("Uporabniški račun je bil uspešno dodan.");
                this.$emit("reload");
                this.newUser = {name: null, email: null};
                this.newPass = null;
            }).catch(error => {
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
                this.$emit("loading", false);
            })
        },
        updateUser() {
            this.$emit("loading", true);
            let url = "/user/update/"+this.user.id;
            api.post(url, this.newUser).then(response => {
                this.$root.$children[0].$refs.warningRef.show("Uporabniški račun je bil spremenjen.");
                this.$emit("reload");
                this.edit = false;
            }).catch(error => {
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
                this.$emit("loading", false);
            })
        },
        cancelEdit() {
            this.newUser = JSON.parse(JSON.stringify(this.user));
            this.edit = false;
        },
        async resetPass() {
            this.confirmData.okBtn = "V redu";
            this.confirmData.message = "S tem dejanjen boste ponastavili geslo. Uporabnik " + this.user.name + " bo na svoj elektronski naslov prejel sporočilo za ponastavitev gesla.";
            const confirm = await this.$refs.confirmDialogue.open();
            if (confirm) {
                let url = "/send-reset-email";
                let data = {email: this.user.email}
                try {
                    const response = await api.post(url, data);
                    this.$root.$children[0].$refs.warningRef.show("Sporočilo je bilo poslano.");
                } catch (error) {
                    let errors = Object.keys(this.errorCatching(error).errors);
                    this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
                }
            }
        },
    }
}
</script>

<style scoped>

.settingsUserRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.settingsUserCol {
    width: 32%;
    display: flex;
    align-items: center;
}

.editUserBtn {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

input {
    width: 100%;
    /* border-color: var(--light-grey); */
}

</style>