<template>
    <div class="pageContainer">
        <entry-page v-if="question == 'start'" :survey="survey" @start="start"/>
        <thankyou-page v-else-if="question == 'finish'" :survey="survey"/>
        <page-template v-else-if="question" :biggerImage="biggerImage" :banner="banner" @back="back" @next="next" :loading="loading"
                       :noBack="noBack" :noButtons="hideButtons">
            <template>
                <transition name="router" mode="out-in">
                    <router-view v-if="question" ref="questionPage" :key="'key' + question" :survey="survey"
                                 :question="question" @set="handleSet" @next="question++" @back="question--"
                                 @conditional="handleConditional" @review="handleReview" @load="handleLoading" />
                </transition>
            </template>
        </page-template>

        <confirm-dialogue ref="confirmDialogue" :okBtn="__('buttons.continueSurvey', $store.state.locale)"
                          :cancelBtn="__('buttons.resetSurvey', $store.state.locale)"
                          :message="__('marks.resetConfirmation', this.$store.state.locale)"></confirm-dialogue>
    </div>
</template>

<script>

export default {
    name: "PageHandler",
    props: {survey: Object},
    data() {
        return {
            biggerImage: null,
            question: null,
            banner: this.survey.head_img,
            tokenName: "progressToken" + this.$route.params.token,
            hideButtons: false,
            noBack: false,
            loading: false,
        }
    },
    methods: {
        setQuestion(q) {
            this.question = q;
        },
        start() {
            this.question = 1;
        },
        next() {
            this.$refs.questionPage.next();
        },
        back() {
            this.$refs.questionPage.back();
        },
        handleSet(biggerImage, banner) {
            this.biggerImage = biggerImage;
            this.banner = banner || this.survey.head_img;
        },
        handleConditional(value) {
            this.noBack = value;
        },
        handleReview(step) {
            if (step == 2)
                this.hideButtons = true;
            else
                this.hideButtons = false;
        },
        handleLoading(value) {
            this.loading = value;
        }
    },
    created() {
        if (this.survey.preview)
            this.question = "start";
    },
    watch: {
        question: {
            deep: true,
            handler(value) {
                if (value > this.survey.questions.length) {
                    this.question = "finish";
                }
                if (value == 1) {
                    this.noBack = true;
                }
            }
        }
    }
}
</script>

<style scoped>


</style>