<template>
    <div class="pageContent" :class="{isItPlain}">
        <div class="scaleRatingsRow">
            <div class="scaleRatingsContent" :class="{fullContent: isItPlain}">
                <div class="scaleDescriptionText">{{ settings.scaleMinText }}</div>
                <div class="scaleDescriptionText">{{ settings.scaleMaxText }}</div>
            </div>
        </div>
        <div class="scaleRatingsRow">
            <div class="scaleRatingsContent" :class="{fullContent: isItPlain}">
                <div v-for="i in settings.scaleSize" :key="i">{{ i }}</div>
            </div>
        </div>
        <scale-ratings-row v-for="(option, index) in settings.options" :image="images" :key="index" :size="settings.scaleSize" :option="option" :plain="isItPlain" :value="values[index]" :mandatory="mandatory" @change="handleChange(...arguments, index)" />
    </div>
</template>

<script>
export default {
    name: "ScaleRatings",
    props: { settings: Object, images: Boolean, value: Array, mandatory: Boolean|Number },
    data() {
        return {
            values: this.settings.options.map(option => {return {value: this.mandatory ? "1" : null, question_id: option.question_id, possible_answer_id: option.id}})
        }
    },
    methods: {
        handleChange(value, index) {
            this.values[index].value = value;
            this.emitChange();
        },
        emitChange() {
            let conditional = this.values.filter(a => a.value == 1 || a.value == 2);
            this.$emit("change", this.values, conditional);
        }
    },
    computed: {
        isItPlain() {
            return this.settings.options.length < 2 && !this.settings.options[0].placeholder;
        }
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(value) {
                if(value) {
                    if(value.length > 0) {
                        this.values = this.value;
                        this.emitChange();
                    } else {
                        this.emitChange();
                    }   
                }         
            }
        }
    },
    created() {
        if(this.$route.name === "QuestionPagePreview")
            this.emitChange();
    }
}
</script>

<style scoped>

.scaleRatingsContent {
    display: flex;
    justify-content: space-between;
}

.scaleDescriptionText {
    /* width: max-content; */
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.scaleDescriptionText:last-child {
    text-align: right;
    margin-left: 15px;
}

@media (min-width:801px) {
    .scaleDescriptionText {
        font-size: 15px;
    }
}

</style>