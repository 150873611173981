<template>
    <div v-if="loading" class="loaderContainerAbsolute">
        <div class="loader"></div>
    </div>

    <div v-else class="container" ref="printAnaliza" id="printAnalizaContainer">
        <div class="printClass">
            <div class="logo-title">
                <img class="printNone" alt="Ekonomska fakulteta"
                     :src="prependUrl(main.logo)">
                <div class="main-title">
                    <span class="naslov-analiza">{{ __("analysis.analysis", main.locale) }}</span><br/>
                    <span class="podnaslov-analiza">{{ main.intern_name }}</span>
                </div>
            </div>
            <img class="printClassBig" alt="Ekonomska fakulteta"
                 :src="prependUrl(main.logo)">
            <div class="print" style="text-align: center;margin-top:30px">
                <span>{{ main.title }} </span> <br><br>
                <span>{{ main.address }} </span>
            </div>

            <l-general-information :survey="main"></l-general-information>
        </div>
        <div class="statistika printNone">
            <span class="statistika-naslov">{{ __("analysis.statistics", main.locale) }}</span>
        </div>
        <div class="no-page-break" v-for="(question,i) in main.questions" :key="i">
            <l-check-answer v-if="question.qtype === 'radiobox' || question.qtype === 'checkbox'"
                            :locale="main.locale"
                            :question="question"
                            @loaded="handleLoad"
            />

            <l-scale-rating v-else-if="question.qtype === 'scale'"
                            :locale="main.locale"
                            :question="question"
                            @loaded="handleLoad"
            />

            <l-text-answer v-else-if="question.qtype === 'text' || question.qtype === 'longtext'"
                           :locale="main.locale"
                           :question="question"
                           @loaded="handleLoad"
            />

            <l-yes-no-answer v-else-if="question.qtype === 'yesno'"
                             :locale="main.locale"
                             :question="question"
                             @loaded="handleLoad"
            />

            <l-review v-else-if="question.qtype === 'review'"
                      :locale="main.locale"
                      :question="question"
                      @loaded="handleLoad"
            />

            <l-instructors-rating v-else-if="question.qtype === 'scaleimage'"
                                  :locale="main.locale"
                                  :question="question"
                                  @loaded="handleLoad"
            />
        </div>

        <custom-modal ref="customModal" :id="id" @print="handlePickedPrint"/>
    </div>
</template>

<script>
function closePrint() {
    document.body.removeChild(this.__container__);
}

function setPrint() {
    var iframeWindow = this.contentWindow;
    iframeWindow.__container__ = this;
    iframeWindow.onbeforeunload = closePrint;
    iframeWindow.onafterprint = closePrint;

    // Poll the page to check if it's ready
    var checkReadyState = setInterval(function () {
        if (iframeWindow.isReadyForPrint) {
            setTimeout(()=>{
                clearInterval(checkReadyState); // Stop polling
                iframeWindow.focus(); // For IE
                iframeWindow.print(); // Print the page
            },800)
        }
    }, 300); // Check every second, adjust as needed
}

function printPage(sURL) {
    let oHiddFrame = document.createElement("iframe");
    oHiddFrame.onload = function () {
        setPrint.call(oHiddFrame); // Ensure 'this' inside setPrint refers to oHiddFrame
    };
    oHiddFrame.style.visibility = "hidden";
    oHiddFrame.style.position = "fixed";
    oHiddFrame.style.right = "0";
    oHiddFrame.style.bottom = "0";
    oHiddFrame.src = sURL;
    document.body.appendChild(oHiddFrame);
}

export default {
    name: "Analiza",
    props: {id: String | Number},
    data() {
        return {
            main: {},
            sidebar: [
                {label: "Analiza ankete", disabled: true, active: true},
                {label: "Natisni", name: "print", active: false},
                {label: "Odpri", name: "openAnalysis", active: false},
                {label: "Po meri", name: "modal", active: false},
                {label: "Excel", name: "excel", active: false},
                {label: "Nazaj", name: "back", active: false},
            ],
            loading: true,
            loaded: 0,
        }
    },
    methods: {
        handleLoad() {
            this.loaded += 1;
            if(this.loaded == this.main.questions.length){
                window.isReadyForPrint = true;
            }
        },
        load() {
            let uri = "/analysis/query-survey/" + this.id;
            api.post(uri).then(response => {
                this.main = response.data.data;
                this.loading = false;
            }).catch(result => {
                if (result.data)
                    console.log(result.data.msg);
                console.log("error");
            });
        },
        print(values) {
            let url = process.env.VUE_APP_URL + "/analysis/" + this.id;
            if (values && values.length > 0) {
                url += "?";
                values.forEach((value, i) => {
                    if (i === 0)
                        url += "?";
                    else
                        url += "&";
                    url += i + "=" + value;
                })
            }
            console.log(url);
            this.$nextTick(function () {
                printPage(url);
                this.$root.$children[0].$refs.warningRef.show("Tiskanje v teku ...");
            });
        },
        openModal() {
            this.$refs.customModal.open();
        },
        handlePickedPrint(values) {
            this.print(values);
        },
        openAnalysis(){
            let url = process.env.VUE_APP_URL + "/analysis/" + this.id;
            window.open(url, '_blank');
        },
        excel() {
            let uri = process.env.VUE_APP_API_URL + "/excel/export/" + this.id;
            window.open(uri, '_blank');
        }
    },
    created() {
        window.isReadyForPrint = false;
        this.load();
        this.$emit("sidebar", this.sidebar);
    },
}
</script>

<style scoped>

.container {
    margin: 0 auto;
    width: 21cm;
}

.container * {
    font-family: "Open Sans", serif;
}

.logo-title {
    position: relative;
    text-align: center;
}

.logo-title img {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
}

.logo-title .naslov-analiza {
    color: #c5093b;
    font-size: 22px;
    font-weight: 600;
}

.logo-title .podnaslov-analiza {
    color: #000;
    font-size: 14px;
    font-weight: 300;
}

.statistika {
    margin-top: 40px;
}

.statistika-naslov {
    font-size: 18px;
    font-weight: 600;
}

.printClassBig {
    display: none;
}

.print {
    font-size: 15px;
    display: none;
}

/*
#iframePrint {
    -webkit-transform: translateY(-110%); 
} */

@media print {
    .printClass {
        margin-top: 40%;
        vertical-align: middle;
    }

    .print {
        display: block;
    }

    .printClassBig {
        display: block;
        margin: auto;
        margin-top: 30px;
        max-width: 50%;
        max-height: 200px;
    }

    .printClass .podnaslov-analiza {
        font-size: 20px;
        margin-top: 10px;
    }

    .printClass .naslov-analiza {
        font-size: 30px;
    }

    .printNone {
        display: none;
    }

}

</style>