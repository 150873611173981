<template>
    <div>
        <carousel paginationActiveColor="var(--main-color)" :perPageCustom="[[0, 2],[800, 3]]">
            <slide v-for="(slide, i) in slides" :key="i">
                <div class="sliderContainer">
                    <img :src="slide.src" alt="">
                    <!-- <div class="slideName">{{ slide.name }}</div>
                    <div class="slideContent">{{ slide.content }}</div>
                    <a class="linkStyle" href="">Preberi več.</a> -->
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
export default {
    name: "ReviewCarousel",
    props: { slides: Array }
}
</script>

<style scoped>

.sliderContainer {
    text-align: center;
    padding: 0 20px;
    position: relative;
}

img {
    /* border-radius: 50%;
    height: 60px;
    width: 60px; */
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.slideName {
    font-weight: bold;
    font-size:12px;
    padding: 10px 0;
}

.slideContent {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 15px;
}

.linkStyle {
    position: absolute;
    color: var(--main-color);
    bottom: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    text-decoration: none;
    font-size: 11px;

}

</style>