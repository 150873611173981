export const reviewGraph = {
    type: "bar",
    data: {
      labels: [1, 2, 3, 4, 5],
      datasets: [{
        backgroundColor: ["red", "green","blue","orange","brown"],
        data: [1, 5, 22, 13, 8]
      }]
    },
    options: {
      legend: {display: false},
      title: {
        display: true,
        text: "Trditev 1"
      }
    }
  };
  
export default reviewGraph;