var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"surveyContainer"},[_vm._m(0),_c('div',{staticClass:"inputRow"},[_c('div',{staticClass:"inputName inputCol"},[_vm._v("Avtor ankete")]),_c('div',{staticClass:"inputCol"},[_c('div',{staticClass:"textAreaStyle roundedStyle"},[_vm._v(_vm._s(_vm.survey.author))])])]),_vm._l((_vm.surveyData),function(d,i){return _c('create-survey-input',{key:'surveyData'+i,attrs:{"data":d,"oldValue":_vm.survey[d.key]},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChange.apply(void 0, argsArray.concat( [d.key] ))}}})}),_c('create-survey-input',{key:'folderKey' + _vm.folders.length,attrs:{"data":{type: 'select', name: 'Mapa', placeholder: 'Ni izbrane mape', key: 'folder_id', options: this.folders},"oldValue":_vm.survey['folder_id']},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChange.apply(void 0, argsArray.concat( ['folder_id'] ))}}}),_vm._m(1),_vm._l((_vm.naslovnaData),function(d,i){return _c('create-survey-input',{key:'naslovnaData'+i,attrs:{"oldValue":_vm.survey[d.key],"data":d},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChange.apply(void 0, argsArray.concat( [d.key] ))}}})}),_vm._m(2),_vm._l((_vm.zadnjaData1),function(d,i){return _c('create-survey-input',{key:'d'+i,attrs:{"data":d,"oldValue":_vm.survey[d.key]},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChange.apply(void 0, argsArray.concat( [d.key] ))}}})}),_c('div',{staticClass:"inputRow"},[_vm._v("Hiperpovezave do socialnih omrežij (Klikni na ikone, ki jih želite dodati)")]),_c('div',{staticClass:"inputRow noHeight"},[_c('social-checkbox-group',{staticClass:"socialIconCheckboxes",attrs:{"options":_vm.socialOptions,"connections":_vm.survey.connections,"name":"social"},on:{"change":_vm.socialCheck}})],1),_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.socialInputs),function(d,i){return _c('span',{key:'e'+i},[(_vm.isSocialChecked(d.id))?_c('create-survey-input',{staticClass:"socialIconInputs",attrs:{"data":d,"oldValue":_vm.isSocialChecked(d.id).url},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.socialLinkInput.apply(void 0, argsArray.concat( [d.id] ))}}}):_vm._e()],1)}),0),_c('div',{staticClass:"divider"}),_vm._l((_vm.zadnjaData2),function(d,i){return _c('create-survey-input',{key:'f'+i,attrs:{"data":d,"oldValue":_vm.survey[d.key]},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChange.apply(void 0, argsArray.concat( [d.key] ))}}})})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputRow"},[_c('div',{staticClass:"uppercaseTitle"},[_vm._v("Vpiši osnovne značilnosti ankete")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputRow surveyInputBckgTitleMargin"},[_c('div',{staticClass:"surveyInputBckgTitle roundedStyle"},[_vm._v("Naslovna stran ankete")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputRow surveyInputBckgTitleMargin"},[_c('div',{staticClass:"surveyInputBckgTitle roundedStyle"},[_vm._v("Zadnja stran ankete")])])}]

export { render, staticRenderFns }