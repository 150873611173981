<template>
    <div class="contentContainer" :class="contentClass">

        <progress-bar :qNum="question" :qSum="survey.questions.length"></progress-bar>

        <div v-if="!showConditional" class="question">{{ theQuestion }}</div>

        <feedback-input v-if="showConditional" :rows="4" :placeholder="__('writeHere', $store.state.locale )"
                        :value="value" :validation="false" :question="currentQuestion" :lowGrade="conditional"/>

        <multi-choice v-else-if="currentQuestion.question_type_id == 1" :options="currentQuestion.possible_answers"
                      :value="value" :validation="validation" @change="handleChange"/>
        <multi-choice v-else-if="currentQuestion.question_type_id == 2" :options="currentQuestion.possible_answers"
                      :value="value" :validation="validation" :multiple="true" @change="handleChange"/>
        <text-input v-else-if="currentQuestion.question_type_id == 5" :rows="1"
                    :placeholder="currentQuestion.placeholder" :value="getValue" :validation="validation"
                    :qid="currentQuestion.id" @change="handleChange"/>
        <scale-ratings v-else-if="currentQuestion.question_type_id == 8"
                       :settings="{options: currentQuestion.possible_answers, scaleSize: 5, scaleMinText: currentQuestion.scale_min, scaleMaxText: currentQuestion.scale_max}"
                       :images="true" :value="value"
                       :mandatory="currentQuestion.preview || setBool(currentQuestion.mandatory)"
                       @change="handleChange"/>
        <text-input v-else-if="currentQuestion.question_type_id == 6" :rows="8"
                    :placeholder="currentQuestion.placeholder" :value="getValue" :validation="validation"
                    :qid="currentQuestion.id" @change="handleChange"/>
        <mailing v-else-if="currentQuestion.question_type_id == 3 && (setBool(currentQuestion.cond) || setBool(currentQuestion.explain))"
                 :question="currentQuestion" :validation="validation" :mailValidation="mailValidation" :value="value"
                 @change="handleChange"/>
        <multi-choice v-else-if="currentQuestion.question_type_id == 3"
                      :options="[{id: null, image:null, placeholder: null, question_id: currentQuestion.id, value:__('buttons.yes', this.$store.state.locale)}, {id: null, image:null, placeholder: null, question_id: currentQuestion.id, value:__('buttons.no', this.$store.state.locale)}]"
                      :validation="validation" :value="value" @change="handleChange"/>
        <scale-ratings v-else-if="currentQuestion.question_type_id == 4"
                       :settings="{options: currentQuestion.possible_answers, scaleSize: 5, scaleMinText: currentQuestion.scale_min, scaleMaxText: currentQuestion.scale_max}"
                       :value="value" :mandatory="currentQuestion.preview || setBool(currentQuestion.mandatory)"
                       @change="handleChange"/>
        <rating-handler v-else-if="currentQuestion.question_type_id == 7" :value="value" :validation="validation"
                        :question="currentQuestion" :step="reviewStep" @change="handleChange" @next="handleStep2"/>
    </div>
</template>

<script>
export default {
    name: "QuestionPage",
    props: {survey: Object, question: Number},
    data() {
        return {
            value: null,
            validation: false,
            conditional: null,
            theQuestion: null,
            showConditional: false,
            review: false,
            reviewStep: 1,
            mailValidation: false,
            contentClass: "",
        }
    },
    methods: {
        load() {
            let url = "/answer/show";
            let data = {
                question_id: this.currentQuestion.id,
                token: localStorage.getItem("progressToken" + this.$route.params.token)
            }

            api.post(url, data).then(response => {
                if (response.data.data) {
                    this.value = response.data.data;
                    this.setValue();
                }
            })
        },
        next() {
            if (parseInt(this.currentQuestion.mandatory) || this.review) {
                this.validation = true;

                // VALIDACIJA STAR RATING - STEP 3
                if (this.review && this.reviewStep == 3 && !this.survey.preview) {
                    if (this.value.testimonial && this.value.testimonial.image && this.value.testimonial.review) {

                        let flag = true;
                        this.value.testimonial.values.forEach(v => {
                            if (!v.value) flag = false;
                        });
                        if (!flag)
                            return false;

                    } else {
                        return false;
                    }
                }
                if (!this.value || this.value.length < 1) return false;

                // ALI JE PRAZEN VALUE PRI TEXTIH
                if ((this.currentQuestion.question_type_id == 5 || this.currentQuestion.question_type_id == 6) && !this.value.value) return false;

            } else if (!this.value || this.value.length < 1) {
                this.value = {question_id: this.currentQuestion.id};
            }

            // VALIDACIJA MAILA NA NEWSLETTER PRIJAVI
            if (this.currentQuestion.question_type_id == 3 && parseInt(this.currentQuestion.cond) && this.value.value == this.__("buttons.yes", this.$store.state.locale)) {
                if (!this.value.email) {
                    this.mailValidation = true;
                    return false;
                }
            }

            let cond = this.conditional ? this.conditional.length > 0 : null;
            cond = this.showConditional ? false : cond;

            if (cond && this.setBool(this.currentQuestion.cond)) {
                this.validation = false;
                this.theQuestion = null;
                this.showConditional = true;
                this.$emit("conditional", false);
            } else if (this.review && this.reviewStep < 3 && this.value.value > 3) {
                this.reviewStep++;
                switch (this.reviewStep) {
                    case 2:
                        this.theQuestion = this.currentQuestion.summary.testimonial_text;
                        break;
                    case 3:
                        this.theQuestion = this.currentQuestion.summary.title;
                        this.validation = false;
                        break;
                    default:
                        this.theQuestion = this.currentQuestion.name;
                }
            } else if (!this.survey.preview) {
                let url = "/answer/store/" + localStorage.getItem("progressToken" + this.$route.params.token);

                let data = this.value.length ? {answers: this.value} : this.value;
                this.$emit("load", true);
                api.post(url, data).then(response => {
                    this.$emit("load", false);
                    console.log(response)
                    this.$emit("next");
                    this.$emit("conditional", false);
                    this.$emit("review", false);
                })
            } else {
                this.$emit("next");
                this.$emit("conditional", false);
                this.$emit("review", false);
            }
        },
        back() {
            if (this.showConditional) {
                this.showConditional = false;
                this.theQuestion = this.currentQuestion.name;
                this.conditional = null;
                if (this.question == 1) {
                    this.$emit("conditional", true);
                }
            } else if (this.review && this.reviewStep == 3) {
                this.reviewStep = 1;
                this.theQuestion = this.survey.questions[this.question - 1].name;
            } else {
                this.$emit("back");
            }
        },
        handleChange(value, conditional) {
            this.value = value;
            this.conditional = conditional;
        },
        setImages() {
            let biggerImage = false;
            switch (parseInt(this.currentQuestion.question_type_id)) {
                case 5:
                    biggerImage = true;
                    break;
                case 3:
                    biggerImage = !parseInt(this.currentQuestion.cond);
                    break;
                default:
                    biggerImage = false;
            }
            if (parseInt(this.currentQuestion.question_type_id) == 3 && setBool(this.currentQuestion.explain))
                biggerImage = false;
            this.$emit("set", biggerImage, this.currentQuestion.banner)
        },
        setValue() {
            let tid = this.currentQuestion.question_type_id;
            if (tid == 2 || tid == 4 || tid == 8) {
                // console.log(this.value)
            } else if (this.value.length > 0) {
                this.value = this.value[this.value.length - 1];
            } else {
                this.value = null;
            }

            if (tid == 7)
                this.review = true;
        },
        handleStep2(yes) {
            this.review = yes;
            if (yes) {
                this.$emit("conditional", false);
            } else {
                delete this.value.testimonial;
            }
            this.next();
        },
    },
    computed: {
        currentQuestion() {
            this.theQuestion = this.survey.questions[this.question - 1].name;
            if (this.survey.preview) {
                if (this.setBool(this.survey.questions[this.question - 1].mandatory) && (this.survey.questions[this.question - 1].question_type_id == 4 || this.survey.questions[this.question - 1].question_type_id == 8)) {
                    this.survey.questions[this.question - 1].preview = true;
                }
                this.survey.questions[this.question - 1].mandatory = false;
            }

            return this.survey.questions[this.question - 1]
        },
        getValue() {
            if (!this.value) {
                return null
            } else {
                return this.value.value
            }
        }
    },
    created() {
        if (this.survey.preview) {
            this.value = [];
            this.setValue();
        } else {
            this.load();
        }
        this.setImages();
    },
    mounted() {
        this.validation = false;
        this.value = this.currentQuestion.value || null;
        const header = document.getElementsByTagName("header")[0];
        const logo = document.getElementsByClassName("logoImg")[0];
        // logo.style = "filter: invert(0)";
        header.style = "color: #fff";
    },
    watch: {
        // showConditional: {
        //     deep: true,
        //     handler(value) {
        //         this.$emit("conditional", !value);
        //     }
        // },
        reviewStep: {
            deep: true,
            handler(value) {
                this.$emit("review", value);
                if (value == 2) {
                    this.contentClass = "noButtons";
                } else {
                    this.contentClass = "";
                }
            }
        }
    }
}
</script>

<style scoped>

.question {
    padding: 12px 0;
    font-weight: 600;
}

.question.title {
    text-align: center;
    text-transform: uppercase;
}

.contentContainer.noButtons {
    padding-bottom: 20px !important;
}


</style>