<template>
    <div class="reviewForm">
        <div class="reviewRow">
            <div class="reviewColumn">
                <input v-for="(c, i) in summary.cols" :key="c.id" v-model="testimonial.values[i].value" type="text" :placeholder="c.column_name" @input="handleChange" class="textAreaStyle roundedStyle" :required="validation">
                <!-- <input v-model="review.name" type="text" placeholder="Ime in Priimek" class="textAreaStyle roundedStyle">
                <input v-model="review.company" placeholder="Podjetje" type="text" class="textAreaStyle roundedStyle"> -->
            </div>
            <!-- <div class="reviewColumn">
                <input v-model="review.role" placeholder="Funkcija v podjetju" type="text" class="textAreaStyle roundedStyle">
            </div> -->
        </div>
        <textarea class="textAreaStyle roundedStyle" :placeholder="__('marks.addReview', $store.state.locale)" rows="4" v-model="testimonial.review" :required="validation"></textarea>
        <div v-if="validation && somethingMissing" class="validationAlert">{{ __("validation.fillTheArea", $store.state.locale) }}</div>

        <div class="imageContainer reviewForm">
            
            <img class="imagePreview" v-if="previewImage" :src="previewImage">
            <div v-if="validation && !testimonial.image" class="disclaimer">{{__("marks.uploadImage", $store.state.locale)}}</div>
            <label class="roundedStyle greyBtn" :class="validation && !testimonial.image ? 'redBtn' : ''" for="file-input">
                    <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
                    {{__("marks.uploadImage", $store.state.locale)}}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "RatingReview",
    props: { summary: Object, validation: Boolean },
    data() {
        return {
            testimonial: {
                values: [],
                review: null,
                image: null,
            },
            previewImage: null
        }
    },
    methods: {
        uploadImage(event) {
            let image = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewImage = e.target.result;
            };

            this.uploadImageDb(image).then(url => {
                this.testimonial.image = url;
                this.handleChange();
            });
        },
        handleChange() {
            this.$emit("change", this.testimonial);
        }
    },
    created() {
        this.summary.cols.forEach(c => {
            this.testimonial.values.push({value: "", summary_column_id: c.id})
        });
    },
    computed: {
        somethingMissing() {
            let flag = true;
            this.testimonial.values.forEach(a => {
                if (!a.value)
                    flag = false;
            })
            if (!this.testimonial.review || !flag)
                return true;
            return false;
        }
    }
}
</script>

<style scoped>

.reviewForm {
    margin-top: 1.1rem;
}

.textAreaStyle {
    margin-bottom: 9px;
}

.disclaimer {
    font-size: 10px;
    color: red;
    opacity: 0.8;
    margin-bottom: 15px;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    font-weight: 500;
    font-size: 14px;
    padding: 8px 26px;
    cursor: pointer;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
    width: fit-content
}

.imagePreview {
    width: 65%;
    max-width: 300px;
    height: auto;
    margin-bottom: 25px;
}

@media (min-width:801px) {
    .reviewRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .reviewColumn {
        width: 400px;
    }

    .textAreaStyle {
        margin-bottom: 12px;
    }

    .disclaimer {
        font-size: 13px;
    }

    label {
        font-weight: 500;
        font-size: 15px;
        padding: 10px 50px
    }
}

</style>