<template>
    <div class="pageContainer">
    <custom-header :admin="true" name="CPOEF" :logo="logo"  />
    <confirm-dialogue ref="confirmDialogueHeader" :okBtn="confirmData.okBtn" :cancelBtn="confirmData.cancelBtn" :message="confirmData.message"></confirm-dialogue>
    <page-template :adminPanel="true" :sidebar="sidebar" @openPage="handlePage">
        <template>
            <transition name="router">
                <router-view @sidebar="handleSidebar" ref="dashboardPage"></router-view>
            </transition>
        </template>
    </page-template>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
    data() {
        return {
            sidebar: [],
            logo: require("../../assets/img/logo.png"),
            confirmData: {
                okBtn: "V redu",
                cancelBtn: "Prekliči",
                message: "S tem dejanjen boste ponastavili geslo. Na vaš elektronski naslov boste prejeli sporočilo za ponastavitev gesla.",
            }
        }
    },
    methods: {
        handleSidebar(value) {
            this.sidebar = value;
        },
        handlePage(value) {
            if (value.name === "print") {
                this.$refs.dashboardPage.print();
            } else if (value.name === "modal") {
                this.$refs.dashboardPage.openModal();
            }else if (value.name === "openAnalysis") {
                this.$refs.dashboardPage.openAnalysis();
            } else if (value.name === "excel") {
                this.$refs.dashboardPage.excel();
            } else {
                let tab = this.sidebarOpen(value);
                if (tab)
                    this.$refs.dashboardPage.changeTab(tab);
            }
        }
    }
}
</script>

<style scoped>

</style>