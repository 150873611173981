<template>
    <div class="splosne-informacije">
        <span class="splosne-informacije-naslov">{{ __("analysis.ginfo", survey.locale) }}</span>
        <table class="si">
            <tr>
                <th>{{ __("analysis.invited", survey.locale) }}</th>
                <th>{{ survey.invited }}</th>
            </tr>
            <tr>
                <td colspan="3">
                    <hr>
                </td>
            </tr>
            <tr>
                <td>{{ __("analysis.finished", survey.locale) }}</td>
                <td>{{ survey.finished }}</td>
                <td>{{ percentage(survey.finished) }}%</td>
            </tr>
            <tr>
                <td>{{ __("analysis.unfinished", survey.locale) }}</td>
                <td style="color: #f00; text-decoration: underline">{{ survey.unfinished }}</td>
                <td>{{ percentage(survey.unfinished) }}%</td>
            </tr>
            <tr>
                <td colspan="3">
                    <hr>
                </td>
            </tr>
            <tr class="final">
                <td>{{ __("analysis.sumarium", survey.locale) }}</td>
                <td>{{ sum }}</td>
                <td>100%</td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: "AnlGeneralInformation",
    props: {survey: Object},
    methods: {
        percentage(val) {
            return this.roundMix((val / this.sum * 100));
        },
    },
    computed: {
        sum() {
            return this.survey.finished + this.survey.unfinished;
        }
    }

}
</script>

<style scoped>

.splosne-informacije, .statistika {
    margin-top: 60px;
}

.splosne-informacije-naslov, .statistika-naslov {
    font-weight: 600;
}

.final td {
    font-weight: bold;
    padding-top: 5px;
    text-transform: uppercase;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

.si tr td:nth-child(1) {
    width: 85%;
}

tr td:first-child, tr th:first-child {
    text-align: left !important;
}

table tr td:last-child, table tr td:last-child {
    text-align: right !important;
}

tr td, tr th {
    text-align: center;
}

th {
    padding-bottom: 10px;
    font-weight: normal;
}

.vprasanje-container {
    page-break-inside: avoid;
}

table td {
    padding: 3px 0;
}

</style>
