import Vue from "vue";
import VueRouter from "vue-router";
import auth from '../middleware/auth';
import strictGuest from "@/middleware/strictGuest";

import AllPages from "../views/AllPages.vue";
import NotFound from "../views/NotFound.vue";

import frontRoutes from "./frontRoutes.js";
import adminRoutes from "./adminRoutes.js";
import SignIn from "../views/SignIn.vue";
import PasswordSet from "../views/PasswordSet.vue";
import ResetPassword from "../views/PasswordReset.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "AllPages",
        component: AllPages,
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/login",
        name: "Prijava",
        component: SignIn,
        meta: {
            middleware: [strictGuest],
        },
    },
    {
        path: "/reset-password",
        name: "Pozabljeno geslo",
        component: ResetPassword,
        meta: {
            middleware: [strictGuest],
        },
    },
    {
        path: "/set-password",
        name: "Nastavi geslo",
        component: PasswordSet,
    },
    ...frontRoutes,
    ...adminRoutes,
    {
        path: "/:pathMatch(.*)*",
        name: "Not Found",
        component: NotFound,
    },
];

// Vue.prototype.$routes = routes;
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


//MIDDLEWARE HANDLER
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({...context, next: nextMiddleware});
    }
    return next();
});


export default router;
