import { render, staticRenderFns } from "./AdmReview.vue?vue&type=template&id=63ee182e&scoped=true&"
import script from "./AdmReview.vue?vue&type=script&lang=js&"
export * from "./AdmReview.vue?vue&type=script&lang=js&"
import style0 from "./AdmReview.vue?vue&type=style&index=0&id=63ee182e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ee182e",
  null
  
)

export default component.exports