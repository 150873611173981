<template>
    <div class="componentContainer">
        <div class="entryPageImgBackground noSelect">
            <img class="imageBackground" :src="setSrc"/>
            <div class="imageOverlay"></div>
            <div class="imageContent" v-if="survey.status !== 'finished'">
                <div class="imageContentSection"></div>
                <div class="imageContentSection">
                    <h1>{{ survey.heading_txt }}</h1>
                </div>
                <div class="imageContentSection pre-line">{{ survey.intro_txt }}</div>

            </div>
            <div class="imageContent" v-else-if="survey.status ==='finished'">
                <div class="imageContentSection"></div>
                <div class="imageContentSection">
                    <h1>{{ __("marks.finishedHead", survey.locale) }}</h1>
                </div>
                <div class="imageContentSection pre-line">{{ __("marks.finished", survey.locale) }}</div>

            </div>
        </div>
        <div v-if="survey.status !== 'finished'" class="centeredContent">
            <button
                class="gradientMainColorBtn fullWidthBtn roundedStyle"
                @click="$emit('start')"
            >
                {{ survey.button_txt }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntryPage",
    props: {survey: Object},
    computed: {
        setSrc() {
            return this.survey.head_img ? this.prependUrl(this.survey.head_img) : require("../../assets/img/background1-desktop.jpg");
        }
    }
};
</script>

<style scoped>
.centeredContent {
    height: 34%;
}

</style>
