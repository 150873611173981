<template>
    <div>
        <div class="no-page-break"  v-for="(a, i) in dataAnalysis" :key="i+'pi'">
            <table class="st4" >
                <tr >
                    <th >{{ a.placeholder }}</th>
                    <th >{{ __("analysis.scale", locale) }}</th>
                    <th >{{ __("analysis.answer", locale) }}</th>
                    <th >{{ __("analysis.percentage", locale) }}</th>
                </tr>

                <tr >
                    <td rowspan="6"><img class="imageStyle" :src="prependUrl(a.image)" alt=""></td>
                </tr>
                <tr v-for="(g,i) in a.graph" :key="'graph'+i">
                    <td style="text-align: center;">{{ i }}</td>
                    <td>{{ g }}</td>
                    <td>{{ roundMix(((g / mySum(a.graph)) * 100)) }}%</td>
                </tr>

                <tr class="final">
                    <td>{{ __("analysis.sumarium", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>{{ mySum(a.graph) }}</td>
                    <td>100%</td>
                </tr>
                <tr class="average final">
                    <td>{{ __("analysis.average", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{{ roundMix(average(a.graph)) }}</td>
                </tr>
            </table>
            <span v-if="a.explanations" class="vprasanje komentar-naslov">{{ __("analysis.comment", locale) }}</span>
            <div v-if="a.explanations">
                <div  v-for="(c,i) in a.explanations" :key="i+'feedback'">
                    <span class="komentar">{{ c.value }}</span>
                </div>
            </div>
            <div class="grafi">
                <div class="graf">
                    <canvas :id="'graph-scale-rating'+a.id+'pa'" style="width:100%;max-width:800px"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Chart from 'chart.js'
import scaleRatingGraph from '../../charts/scaleRating.js'

export default {
    name: "Lecturer",
    props: {id: String | Number},
    data() {
        return{
            dataAnalysis: [],
            locale: "sl"
        }
    },
    methods:{
        async load() {
            let url = "/analysis/query-lecturer/" + this.id;
            await api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
                this.$nextTick(function(){
                    this.drawGraphs();
                })
            });
        },
        drawGraphs() {
            let tmp = JSON.parse(JSON.stringify(this.dataAnalysis));
            for (let i = 0; i < tmp.length; i += 1) {
                this.drawGraph(document.getElementById('graph-scale-rating' + tmp[i]["id"] + 'pa'), tmp[i]);
            }
        },
        drawGraph(ctx, data, format = "bar") {
            let labels = Object.keys(data["graph"]);
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: this.colors1to5(),
                        data: Object.values(data["graph"])
                    }]
                },
                options: {
                    legend: {display: false},
                    title: {
                        display: true,
                        text: data["placeholder"],
                    }
                }
            };
            new Chart(ctx, graphData);
        },
        mySum(graph) {
            let sum = Object.values(graph).reduce((partial_sum, a) => partial_sum + a, 0);
            return sum === 0 ? 1 : sum;
        },

    },
    created(){
        this.load();
    }
}
</script>


<style scoped>

.container {
    margin: 0 auto;
    width: 21cm;
}

.container * {
    font-family: "Open Sans";
}

.logo-title {
    position: relative;
    text-align: center;
}

.logo-title img {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
}

.logo-title .naslov-analiza {
    color: #c5093b;
    font-size: 22px;
    font-weight: 600;
}

.logo-title .podnaslov-analiza {
    color: #000;
    font-size: 14px;
    font-weight: 300;
}

.statistika {
    margin-top: 40px;
}

.statistika-naslov {
    font-size: 18px;
    font-weight: 600;
}

.printClassBig {
    display: none;
}

.print {
    font-size: 15px;
    display: none;
}

/*
#iframePrint {
    -webkit-transform: translateY(-110%);
} */

@media print {
    .printClass {
        margin-top: 40%;
        vertical-align: middle;
    }

    .print {
        display: block;
    }

    .printClassBig {
        display: block;
        margin: auto;
        margin-top: 30px;
        max-width: 50%;
        max-height: 200px;
    }

    .printClass .podnaslov-analiza {
        font-size: 20px;
        margin-top: 10px;
    }

    .printClass .naslov-analiza {
        font-size: 30px;
    }

    .printNone {
        display: none;
    }

}

.komentar-naslov {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 6px;
}
.komentar {
    font-size: 14px;
    display: block;
    padding: 5px 10px;
    margin-bottom:3px;
    border-radius: 4px;
    width:100%;
    background-color: #e6e6e6;
}

.vprasanje-container {
    page-break-inside: avoid;
}
.imageStyle{
    height: 60px;
    width: 60px;
    border-radius:100%;
    object-fit: cover;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.final td{
    border-top:1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.final.average td {
    border-top: none;
    padding-top: 0;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

tr td, tr th {
    text-align: center;
}

th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;;
}

.st4 tr td {
    width: 25%;
}

@media print {
    .printClass {
        margin-top: 40%;
        vertical-align: middle;
    }

    .print {
        display: block;
    }

    .printClassBig {
        display: block;
        margin: auto;
        margin-top: 30px;
        max-width: 50%;
        max-height: 200px;
    }

    .printClass .podnaslov-analiza {
        font-size: 20px;
        margin-top: 10px;
    }

    .printClass .naslov-analiza {
        font-size: 30px;
    }

    .printNone {
        display: none;
    }

}

</style>
