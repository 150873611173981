<template>
  <div class="multiChoiceContainer" :class="marginClass(margin)">
    <div v-for="(option, index) in options" :key="index" class="choiceContainer noSelect">
      <input v-model="newValue" :type="type" name="multiChoice" :id="'multiChoice'+index" :value="option" @change="handleEmit">
      <label :for="'multiChoice'+index" class="choiceBox roundedStyle noSelect">{{ option.value }}</label>
    </div>
    <div v-if="validation && newValue.length < 1" class="validationAlert">{{ __("validation.pickOneOption", $store.state.locale) }}</div>
  </div>
</template>

<script>
export default {
    name: "MultiChoice",
    props: { options: Array, multiple: Boolean, margin: {type: String, default: "auto"}, value: String|Array, validation: Boolean },
    data() {
      return {
        type: this.multiple ? "checkbox" : "radio",
        newValue: []
      }
    },
    methods: {
        handleEmit() {
            let data;
            if (this.multiple) {
                data = [];
                this.newValue.forEach(a => {
                    let tmp = {};
                    tmp.question_id = a.question_id;
                    tmp.value = a.value;
                    tmp.possible_answer_id = a.id;
                    tmp.email = null;
                    data.push(tmp);
                });
            } else {
                data = {question_id: this.newValue.question_id, email: null, value: this.newValue.value, possible_answer_id: this.newValue.id };
            }
            this.$emit('change', data);
        }
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if (value) {
                    if(!this.multiple) {
                        this.newValue = {value: value.value, id: value.possible_answer_id, image: null, placeholder: null, question_id: this.options[0].question_id};
                    } else {
                        if (value.length) {
                            this.newValue = [];
                            value.forEach(a => {
                                let tmp = {
                                    value: a.value,
                                    id: a.possible_answer_id,
                                    image: null,
                                    placeholder: null,
                                    question_id: a.question_id
                                }
                                this.newValue.push(tmp);
                            })
                        } else if (value.value) {
                            let tmp = {
                                    value: value.value,
                                    id: value.possible_answer_id,
                                    image: null,
                                    placeholder: null,
                                    question_id: value.question_id
                                }
                            this.newValue = [tmp];
                        }
                        
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>