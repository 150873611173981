<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>
        <div class="adminAnswersInput">
            <div v-for="(a,i) in possibleAnswers" :key="i" class="adminAnswersInputSingle flexElement">
                <input type="text" class="roundedStyle textAreaStyle" :placeholder="'Vpišite besedilo odgovora '+(i+1)" v-model="a.value" @blur="answerInput">
                <button v-if="possibleAnswers.length > 2" @click="deletePossibleAnswer(i)" class="iconBtn"><img src="../../../assets/img/trash_icon.svg" alt="Delete"></button>
            </div>
            <div class="adminAnswersInputSingle">
                <div class="roundedStyle textAreaStyle disabledRoundedStyle">
                    <span>Vpišite besedilo odgovora {{ possibleAnswers.length + 1}}</span>
                    <button class="addOptionBtn iconBtn" @click="addAnswer"><img src="../../../assets/img/add_icon_small.svg" alt="ADD"></button>
                </div>
            </div>
        </div>
        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" :oldValue="item.banner" @change="handleImage" />
        </div>

        <checkbox-group :options="checkbox"  width="100%" :name="item.type + item.index" @change="handleChange" />
    </div>
</template>

<script>
export default {
    name: "AdmCheckAnswer",
    props: {item: Object},
    data() {
        return {
            possibleAnswers: this.item.possible_answers.length > 0 ? this.item.possible_answers : [{value: ""}, {value: ""}],
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            checkbox: [{boldLabel: "Obvezen odgovor:", label: "anketirancu bo onemogočeno nadaljevanje, dokler ne odgovori na vprašanje.", checked: this.setBool(this.item.mandatory)}]
        }
    },
    methods: {
        addAnswer() {
            this.possibleAnswers.push({value: ""});
        },
        handleChange(value) {
            this.item.mandatory = !!value.length;
        },
        answerInput() {
            this.item.possible_answers = this.possibleAnswers;
        },
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        handleImage(value) {
            this.item.banner = value;
        },
        deletePossibleAnswer(index) {
            this.possibleAnswers.splice(index, 1);
            this.answerInput();
        }
    }
}
</script>

<style scoped>

</style>