<template>
    <div>
    </div>
</template>

<script>
export default {
    name: "AllPages",
    beforeCreate() {
        this.$router.push("/admin")
    }
};
</script>

<style scoped>


</style>
