<template>
    <page-template :noButtons="true">
        <template>
            <div class="contentContainer noButtons">
                <div class="formContainer">
                    <div class="heading">KREACIJA NOVEGA GESLA</div>
                    <form action="#" @submit.prevent="submit">

                        <div class="inputDiv roundedStyle textAreaStyle">
                            <span class="iconImg"><img :src="require('../assets/img/lock.svg')" alt=""></span>
                            <input type="password" name="password" id="password" class="inputStyle"
                                   v-model="password" placeholder="Novo geslo" @input="alert = false">
                        </div>
                        <div class="inputDiv roundedStyle textAreaStyle">
                            <span class="iconImg"><img :src="require('../assets/img/lock.svg')" alt=""></span>
                            <input type="password" name="password" id="password_confirmation" class="inputStyle"
                                   v-model="password_confirmation" placeholder="Potrditev gesla" @input="alert = false">
                        </div>
                        <div v-if="alert" class="validationAlert ">Vpisani gesli se ne ujemata!</div>
                        <div class="buttonDiv">
                            <button type="submit" class="roundedStyle mainColorBtn">
                                Potrdi
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </page-template>
</template>

<script>

export default {
    name: 'PasswordSet',

    data() {
        return {
            password: null,
            password_confirmation: null,
            alert: false
        }
    },

    methods: {
        submit() {
            if (this.password == this.password_confirmation) {
                let url = "/set-password";
                let data = {
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.$route.query.token
                };
                api.post(url, data).then(response => {
                    this.$root.$children[0].$refs.warningRef.show("Geslo je bilo uspešno spremenjeno");
                    setTimeout(() => {
                        location.href="/";
                    }, 1000);
                }).catch(error => {
                    let errors = this.errorCatching(error);
                    let firstError = errors.errors[Object.keys(errors.errors)[0]][0];
                    this.$root.$children[0].$refs.warningRef.show(firstError);
                });
            } else {
                this.alert = true;
            }
            
        }
    }
}
</script>

<style scoped>
.passForgot {
    margin-left: 32px;
    color: var(--main-color);
    text-decoration: none;
    font-size: 17px;
}

.text-right {
    text-align: right;

}

.w-70 {
    width: 70% !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block;
}

.w-30 {
    width: 30% !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block;
}

.validationAlert {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: center;
}

.iconImg {
    left: -25px;
    position: relative;
    top: 4px;
}

.inputStyle {
    width: 340px;
    margin-right: 20px;
    border: none;
    color: #707070;
    height: 100%;
    font-size: 16px;
    background-color: transparent;
}

::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 16px;
    background-color: transparent;

}

.heading {
    font-family: "Open Sans", monospace;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    padding: 20px 30px;
    margin-bottom: 40px;
}

.formContainer {
    height: fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
}

.inputDiv {
    width: 420px;
    height: 44px;
    margin: 14px;
    padding: 0;
    text-align: right;
    border-radius: 59px;
    border: solid 1px #707070;
}

label {
    font-size: 14px;
}

.buttonDiv {
    text-align:center;
}

button {
    width: 200px;
}
</style>