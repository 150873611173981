import Vue from "vue";
import Vuex from 'vuex'
import App from "./App.vue";
import store from "./store";
import mixin from "./mixing";
import router from "./router";
import './assets/css/style.css';
import {authAPI} from "./config.js";
//GLOBAL VARIABLES
window.api = authAPI;
window.store = store;
Vue.config.productionTip = false;

Vue.use(Vuex);

/* OUTSIDE COMPONENTS */

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import StarRating from 'vue-star-rating'
import VueCarousel from 'vue-carousel';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import infiniteScroll from 'vue-infinite-scroll'

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {CategoryScale, LinearScale, BarController, BarElement, ArcElement, PieController, Legend, Tooltip, Title} from 'chart.js';
Chart.register(CategoryScale, LinearScale, BarController, BarElement,ChartDataLabels, ArcElement, PieController, Legend, Tooltip, Title)

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('VueSlider', VueSlider)
Vue.component('star-rating', StarRating);
Vue.use(infiniteScroll);
Vue.use(VueCarousel, Chart);

/* MIXINS */

Vue.mixin(mixin);


/* PAGES */

Vue.component('entry-page', require('./views/front/EntryPage.vue').default);
Vue.component('thankyou-page', require('./views/front/ThankYouPage.vue').default);


/*  COMPONENTS  */

Vue.component('custom-header', require('./components/CustomHeader.vue').default);
Vue.component('header-modal', require('./components/HeaderModal.vue').default);
Vue.component('page-template', require('./components/PageTemplate.vue').default);
Vue.component('multi-choice', require('./components/MultiChoice.vue').default);
Vue.component('progress-bar', require('./components/ProgressBar.vue').default);
Vue.component('scale-ratings', require('./components/ScaleRatings.vue').default);
Vue.component('scale-ratings-row', require('./components/ScaleRatingsRow.vue').default);
Vue.component('text-input', require('./components/TextInput.vue').default);
Vue.component('feedback-input', require('./components/FeedbackInput.vue').default);
Vue.component('mailing', require('./components/Mailing.vue').default);
Vue.component('rating-handler', require('./components/RatingHandler.vue').default);
Vue.component('rating', require('./components/Rating.vue').default);
Vue.component('rating-yes-no', require('./components/RatingYesNo.vue').default);
Vue.component('rating-review', require('./components/RatingReview.vue').default);
Vue.component('rating-condition', require('./components/admin/RatingCondition.vue').default);
Vue.component('testimonial-upload', require('./components/admin/TestimonialUpload.vue').default);
Vue.component('review-carousel', require('./components/ReviewCarousel.vue').default);
Vue.component('single-star', require('./components/SingleStar.vue').default);
Vue.component('confirm-dialogue', require('./components/ConfirmDialogue.vue').default);
Vue.component('custom-modal', require('./components/CustomModal.vue').default);
Vue.component('mail-table', require('./components/admin/MailTable.vue').default);
Vue.component('warning-popup', require('./components/admin/Warning.vue').default);

// ADMIN COMPONENTS
Vue.component('admin-sidebar', require('./components/admin/Sidebar.vue').default);
Vue.component('draggable-item', require('./components/admin/DraggableItem.vue').default);
Vue.component('checkbox-group', require('./components/admin/CheckboxGroup.vue').default);
Vue.component('social-checkbox-group', require('./components/admin/SocialCheckboxGroup.vue').default);
Vue.component('create-survey-input', require('./components/admin/CreateSurveyInput.vue').default);
Vue.component('q-type-btn', require('./components/admin/QuestionTypeButton.vue').default);
Vue.component('settings-user', require('./components/admin/SettingsUser.vue').default);
Vue.component('drag-survey-list', require('./components/admin/DraggableSurveyList.vue').default);
Vue.component('drag-survey-row', require('./components/admin/DraggableSurveyRow.vue').default);

// ADMIN EDIT SURVEY
Vue.component('step1', require('./components/admin/survey/SurveyStep1.vue').default);
Vue.component('step2', require('./components/admin/survey/SurveyStep2.vue').default);
Vue.component('step3', require('./components/admin/survey/SurveyStep3.vue').default);


// ADMIN QUESTIONS COMPONENTS
Vue.component('a-check-answer', require('./components/admin/questions/AdmCheckAnswer.vue').default);
Vue.component('a-yes-no-answer', require('./components/admin/questions/AdmYesNoAnswer.vue').default);
Vue.component('a-scale-rating', require('./components/admin/questions/AdmScaleRating.vue').default);
Vue.component('a-text-answer', require('./components/admin/questions/AdmTextAnswer.vue').default);
Vue.component('a-review', require('./components/admin/questions/AdmReview.vue').default);
Vue.component('a-instructors-rating', require('./components/admin/questions/AdmInstructorsRating.vue').default);
Vue.component('a-instructors-rating-row', require('./components/admin/questions/AdmInstructorsRatingRow.vue').default);

// ADMIN ANALYSIS COMPONENTS
Vue.component('l-check-answer', require('./components/admin/analysis/AnlCheckAnswer.vue').default);
Vue.component('l-scale-rating', require('./components/admin/analysis/AnlScaleRating.vue').default);
Vue.component('l-text-answer', require('./components/admin/analysis/AnlTextAnswer.vue').default);
Vue.component('l-text-answer-row', require('./components/admin/analysis/AnlTextAnswerRow.vue').default);
Vue.component('l-yes-no-answer', require('./components/admin/analysis/AnlYesNoAnswer.vue').default);
Vue.component('l-review', require('./components/admin/analysis/AnlReview.vue').default);
Vue.component('l-instructors-rating', require('./components/admin/analysis/AnlInstructorsRating.vue').default);
Vue.component('l-general-information', require('./components/admin/analysis/AnlGeneralInformation.vue').default);
Vue.component('l-testimonials', require('./components/admin/analysis/AnlTestimonials.vue').default);


Promise.all([
    store.dispatch('auth/me'),
    store.dispatch('trans/loadTranslations')
]).then(() => {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})

