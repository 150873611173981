import Dashboard from "../views/admin/Dashboard.vue";
import AdminEntry from "../views/admin/AdminEntry.vue";
import Settings from "../views/admin/Settings.vue";
import Analiza from "../views/admin/Analiza.vue";
import Lecturer from "../views/admin/Lecturer.vue";
import Surveys from "../views/admin/Surveys.vue";
import CreateSurvey from "../views/admin/CreateSurvey.vue";
import EditSurvey from "../views/admin/EditSurvey.vue";
import Analysis from "../views/admin/Analysis.vue";
import SurveyListAll from "../views/admin/SurveyListAll.vue";
import SurveyList from "../views/admin/SurveyList.vue";

// preview views from front
import TheSurvey from "../views/front/TheSurvey.vue";
import PageHandler from "../views/front/PageHandler.vue";
import QuestionPage from "../views/front/QuestionPage.vue";

import auth from "@/middleware/auth";
import log from "@/middleware/log";

let debug = false;
let debuggerMode = [auth, log];
let mode = debug ? debuggerMode : [auth];


export default [
    {
        path: "/admin/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            middleware: mode,
        },
        children: [
            {
                path: "",
                name: "AdminEntry",
                component: AdminEntry,
                meta: {
                    middleware: mode,
                },
            },
            {
                path: "settings",
                name: "Settings",
                component: Settings,
                meta: {
                    middleware: mode,
                },
            },
            {
                path: "surveys",
                name: "Surveys",
                component: Surveys,
                meta: {
                    middleware: mode,
                },
                children: [
                    {
                        path: "all",
                        name: "SurveyListAll",
                        component: SurveyListAll,
                        meta: {
                            middleware: mode,
                        },
                    },
                    {
                        path: "create-survey",
                        name: "CreateSurvey",
                        component: CreateSurvey,
                        meta: {
                            middleware: mode,
                        },
                    },
                    {
                        path: ":filter",
                        name: "SurveyList",
                        component: SurveyList,
                        props: true,
                        meta: {
                            middleware: mode,
                        },
                    },
                ]
            },
            {
                path: "survey/:id",
                name: "EditSurvey",
                component: EditSurvey,
                props: true,
                meta: {
                    middleware: mode,
                },
            },
            {
                path: "analysis/:id",
                name: "Analysis",
                component: Analiza,
                props:true,
                meta: {
                    middleware: mode,
                },
            },

        ]
    },  
    {
        path: "/analysis/:id",
        name: "Print",
        component: Analiza,
        props: true,
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/lecturer/:id",
        name: "LecturerPrint",
        component: Lecturer,
        props: true,
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/preview/:id",
        name: "SurveyPreview",
        component: TheSurvey,
        props: true,
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: "/",
                name: "PageHandlerPreview",
                props: true,
                component: PageHandler,
                meta: {
                    middleware: mode,
                },
                children: [
                    {
                        path: "/",
                        name: "QuestionPagePreview",
                        component: QuestionPage,
                        meta: {
                            middleware: mode,
                        },
                    },
                ]
            },
        ]
    },
]